import React, { useState, useEffect, useRef , useMemo } from 'react';
import { Card, Col, Row, Input, Select, Pagination, Empty } from 'antd'; // Empty 컴포넌트 추가
import './TableCard.scss';
import { Link } from 'react-router-dom';
import common from '../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const { Option } = Select;

function TableCard({ data = [], pageSize = 10, categoryOptions = [] , type="Editor",}) {
    // 검색 카테고리 상태
    const [searchCategory, setSearchCategory] = useState('전체');
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();



    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    
    const currentData = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        return data.slice(startIndex, endIndex);
    }, [data, currentPage, pageSize]);

    const currentPageIdxArray = useMemo(() => {
        return currentData.map(item => item.idx);
    }, [currentData]);

    const [updatedData, setUpdatedData] = useState([]);
    useEffect(() => {
        console.log("currentPageIdxArray:", currentPageIdxArray);
        console.log("currentData:", currentData);
    
        if (currentPageIdxArray.length > 0) {
            const fetchThumbnailData = async () => {
                try {
                    const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_t_table`, { idx_pack: currentPageIdxArray }, {
                        withCredentials: true
                    });
    
                    if (response.data.result === 't') {
                        const thumbnailData = response.data.data;
    
                        console.log('thumbnailData', thumbnailData);
    
                        // 데이터 병합 로직
                        const mergedData = currentData.map(item => {
                            const thumbnailItem = thumbnailData.find(thumbnail => thumbnail.idx === item.idx);
                            return {
                                ...item,
                                thumbnail: thumbnailItem ? thumbnailItem.thumbnail : '' // 썸네일이 없으면 기본값 사용
                            };
                        });
    
                        setUpdatedData(mergedData);
                        // setFilteredData(updatedData); // 상태 업데이트
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    console.error('데이터 전송 실패:', error);
                }
            };
    
            fetchThumbnailData();
        }
    }, [currentPageIdxArray]);

    // 페이지 변경 핸들러
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    


    // 검색 카테고리 변경 핸들러
    const handleSearchCategoryChange = (value) => {
        setSearchCategory(value);
        handleSearch(searchText, value);
    };

    // 검색 텍스트 변경 핸들러
    const handleSearchTextChange = (e) => {
        const value = e.target.value;
        setSearchText(value);
        handleSearch(value, searchCategory);
    };

    // 검색 기능 핸들러
    const handleSearch = (text, category) => {
        if (text === '') {
            setFilteredData(data);
            return;
        }
    
        const filtered = data.filter(item => {
            if (category === '전체') {
                // 아이템 객체의 모든 필드를 확인합니다.
                return Object.values(item).some(value => 
                    typeof value === 'string' && value.includes(text)
                );
            } else {
                // 특정 카테고리만 확인합니다.
                const value = item[category];
                return value && typeof value === 'string' && value.includes(text);
            }
        });
    
        setFilteredData(filtered);
    };

    return (
        <div className='p_tableCard_box'>
            <div className="p_tableCard_main_box">
                {filteredData.length === 0 ? (
                    // 데이터가 없을 때 표시할 Empty 컴포넌트
                    <>
                    {type === 'Report' || type === 'relatedOrganization' ? (
                        <>
                        <Empty description="No data available" />
                        </>
                    ):(
                        <>
                        <Empty description="내역이 없습니다" />
                        </>
                    )}
                    
                    </>
                ) : (
                    <>
                        {/* 그리드 레이아웃을 만드는 Row 컴포넌트 */}
                        <Row>
                        {filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((item) => {
                            const updatedItem = updatedData.find(updated => updated.idx === item.idx);
                            
                            return (
                                <Col span={8} key={item.idx}>
                                    {/* 카드 레이아웃을 만드는 Card 컴포넌트 */}
                                    <Card
                                        cover={<div style={{
                                            backgroundImage: `url(${updatedItem ? updatedItem.thumbnail : ''})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center center',
                                            backgroundSize: 'cover',
                                        }}
                                            className='thumbnail_img' />}
                                    >
                                        <div className="card_wrap">
                                            <Card.Meta
                                                title={
                                                    type === 'relatedOrganization' ? (
                                                        <span className='card_title_btn'>{item.title}</span>
                                                    ) : (
                                                        <>
                                                        {type === 'Report' ? (
                                                            <>
                                                            <Link
                                                                to={`/engdetail?id=${item.idx}`}
                                                                state={item}
                                                                className='card_title_btn'
                                                            >
                                                                {item.title}
                                                            </Link>
                                                            </>
                                                        ):(
                                                            <>
                                                            <Link
                                                                to={`/noticedetail?id=${item.idx}`}
                                                                state={item}
                                                                className='card_title_btn'
                                                            >
                                                                {item.title}
                                                            </Link>
                                                            </>
                                                        )}
                                                        </>
                                                    )
                                                }
                                                description={
                                                    <>
                                                        <div className="card_box">
                                                            <div>{item.createTime}</div>
                                                            <div className='card_views'></div>
                                                            <span className='card_count' key="views">{item.view}</span>
                                                        </div>
                                                    </>
                                                }
                                            />
                                            {type === 'relatedOrganization' ? (
                                                <>
                                                    <a 
                                                        className='card_detail_btn'
                                                        href={`https://www.newcaets.org/`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        direct link
                                                    </a>
                                                </>
                                            ):(
                                                <>
                                                    {type === 'Report' ? (
                                                        <>
                                                        <Link 
                                                            className='card_detail_btn'
                                                            to={`/engdetail?id=${item.idx}`}
                                                            state={item}
                                                        >
                                                        Read More
                                                        </Link>
                                                        </>
                                                    ):(
                                                        <>
                                                        <Link 
                                                            className='card_detail_btn'
                                                            to={`/noticedetail?id=${item.idx}`}
                                                            state={item}
                                                        >
                                                        자세히보기
                                                        </Link>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </Card>
                                </Col>
                            );
                        })}
                        </Row>
                        {/* 페이지네이션 컴포넌트 */}
                        <Pagination
                            current={currentPage}
                            total={filteredData.length}
                            pageSize={pageSize}
                            onChange={handlePageChange}
                        />
                    </>
                )}
            </div>

            {/* 검색 기능을 위한 컨테이너 */}
            <div className="search_container_wrap">
                <div className="search_container">
                    <Select className='p_select p_table_select' value={searchCategory} onChange={handleSearchCategoryChange}>
                        {categoryOptions.map(option => (
                            <Option key={option.value} value={option.value} className="p_table_option">{option.label}</Option>
                        ))}
                    </Select>
                    <Input
                        className='p_input p_table_search_input'
                        placeholder={type === "Report" ? "Please enter your search term" : "검색어를 입력해주세요."}
                        value={searchText}
                        onChange={handleSearchTextChange}
                    />
                    <button className='p_search_icon'></button>
                </div>
            </div>
        </div>
    );
}

export default TableCard;
