import styles from './Ci.module.scss';
import { FaDownload } from 'react-icons/fa';
function Ci() {
    
    return (
        <>
            <div className="components_wrap">
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>로고</span>
                    </div>
                    <div className="cont_box">
                        <div className={`${styles.logo_img_box} cont_img_box`}>
                            <img src="/img/symbol1-1.png" className='' alt="" style={{}} />
                            <img src="/img/symbol1-2.png" className='' alt="" style={{}} />
                        </div>
                        <p>우리 로고는 영문 명칭(The National Academy of Engineering of Korea)의 이니셜인 ‘NAEK’로 이루어져 있습니다.</p>
                        <p>현대적인 감각과 느낌을 표현하며 가시성을 향상시킨 심플한 레터마크 로고입니다.</p>
                    </div>
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>로고 타입</span>
                    </div>
                    <div className={`${styles.logo_img_2} cont_box`}>
                        <div className={`${styles.logo_img_box_2}`}>
                            <div className={`${styles.border}`}>
                                <img src="/img/symbol2.png" className='' alt="" style={{}} />
                            </div>
                            <p>한국공학한림원의 로고와 국문 명칭을 조합한 CI</p>
                        </div>
                        <div className={`${styles.logo_img_box_2}`}>
                            <div className={`${styles.border}`}>
                                <img src="/img/symbol3.png" className='' alt="" style={{}} />
                            </div>
                            <p>한국공학한림원의 로고와 국영문 명칭을 조합한 CI</p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.ci_btn_wrap}`}>
                    <div className={`${styles.ci_btn_box}`}>

                        <a href="/img/NAEK_CI_PACK.zip" download className={`${styles.ci_img}`}>
                            <span>CI 이미지 다운로드</span>
                            <FaDownload className={`${styles.ci_icon}`} />
                        </a>
                    </div>
                    <div className={`${styles.ci_btn_box}`}>
                        <a href="/img/NAEK_Ci.ai" download className={`${styles.ci_img_ai}`}>
                            <span>CI AI 파일 다운로드</span>
                            <FaDownload className={`${styles.ci_icon}`} />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ci;