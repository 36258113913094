import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import common from '../../../common';
import { useLocation } from 'react-router-dom';

import styles from './Dept.module.scss';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// 지울꺼
import { TableList } from "../../../components";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Committee() {
    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [getData, setGetData] = useState([]);//
    const firCate = queryParams.get('code') || 1;
    const [cate1, setCate1] = useState(0);//
    const [cate2, setCate2] = useState(0);//
    const [CategoryData, setCategoryData] = useState([]);//
    const [SubNameData, setSubNameData] = useState([]);//
    

    const [oCategory, setCategory] = useState(queryParams.get('category') || '이사회');//
    const [oName, setName] = useState(queryParams.get('name') || '전체');//

    const [cateReady, setCateReady] = useState(false);//
    const [nameReady, setNameReady] = useState(false);//
    
    const [firstCheck, setFirstCheck] = useState(true);//

    const getAllCate = async () => {
        console.log('getAllCate');
        console.log('');
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_organization/out/get_allcate`,
                {},
                { withCredentials: true }
            );
            if (response && response.data && response.data.result === 't') {
                setCategoryData(response.data.data1)
                onChgCate(oCategory)
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };
    useEffect(() => {
        getAllCate();
        setFirstCheck(false);
    }, []);
    const onChgCate = async (isCate) => {
        console.log('onChgCate');
        console.log('');
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_organization/out/get_cate`,
                { committee_category: isCate },
                { withCredentials: true }
            );
            if (response && response.data && response.data.result === 't') {
                setSubNameData(response.data.data2)
                // setCateReady(true);
                if(firstCheck){
                    getMember(isCate,oName);
                }else{
                    getMember(isCate,'전체');
                }
                
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };

    const getMember = async (isCate,isName) => {
        console.log('getMember');
        console.log('');
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_organization/out/get_list`,
                {
                    committee_category: isCate,
                    committee_name: isName
                },
                { withCredentials: true }
            );
            if (response && response.data && response.data.result === 't') {
                setGetData(response.data.data)
                setCateReady(true);
                setNameReady(true);
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };
    useEffect(() => {
        console.log('useEffect oCategory');
        if(cateReady==true && nameReady==true){
            onChgCate(oCategory);
            console.log('useEffect oCategory onChgCate');
        }
        console.log('');
    }, [oCategory]);

    useEffect(() => {
        console.log('useEffect oName');
        if(cateReady==true && nameReady==true){
            getMember(oCategory,oName);
            console.log('useEffect oName getMember');
        }
        console.log('');
    }, [oName]);
    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <div className={styles.committee_tabs}>
                    <button className={styles.committee_active_tab}>위원회 현황</button>
                </div>

                <div className={styles.committee_select_box}>
                    <select
                        className={styles.committee_item}
                        value={oCategory}
                        onChange={(e) => {
                            setCategory(e.target.value);
                        }}
                    >
                        {CategoryData.map((option,index) => (
                            <option key={`1_${index}_${option.category}`} value={option.category}>{option.category}</option>
                        ))}
                    </select>
                    <select
                        className={styles.committee_item}
                        value={oName}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    >
                        {SubNameData.map((option,index) => (
                            <option key={`2_${oCategory}_${option.name}`} value={option.name}>{option.name}</option>
                        ))}
                    </select>
                </div>
                <div className={styles.table_list_box}>
                    <span className={styles.result_count}>회원수 : <span className={styles.color_blue}>{getData.length} 명</span></span>
                    <div className={`naek_table ${styles.naek_committee_table}`}>
                        <DataTable
                            value={getData}
                            paginator
                            rows={50}
                            // dataKey="memberIDX"
                            dataKey={(rowData) => `${rowData.memberIDX}_${rowData.committeeGrade}`}
                            emptyMessage="해당 위원회에 소속된 회원이 존재하지 않습니다." // 메시지 변경
                        >
                            <Column field="committeeGrade" header="직책" bodyClassName="text-center"></Column>
                            <Column field="name" header="성명" bodyClassName="text-center"></Column>
                            <Column field="organization" header="소속" bodyClassName="text-center"></Column>
                            <Column field="position" header="직위" bodyClassName="text-center"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Committee;