import styles from './Status.module.scss';
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import Highcharts3d from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import common from '../../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


Highcharts3d(Highcharts);

function MembersIntro() {

    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const [regularDepartmentData, setRegularDepartmentData] = useState([]);
    const [normalDepartmentData, setNormalDepartmentData] = useState([]);
    const [oldDepartmentData, setOldDepartmentData] = useState([]);
    const [forignerDepartmentData, setForignerDepartmentData] = useState([]);
    const [totalData, setTotalData] = useState([]);



    const [regularMemberData, setRegularMemberData] = useState([]);
    const [normalMemberData, setNormalMemberData] = useState([]);

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [isMobileView2, setIsMobileView2] = useState(window.innerWidth <= 500);

    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 768);
        setIsMobileView2(window.innerWidth <= 500);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const colorArray = ['#e8c162', '#c2d47e', '#874221', '#e2e2e0', '#eddca6', '#e2e2e0', '#dce1bd', '#c2d47e'];
    const colorArray2 = ['#eedad3', '#f8c8c6', '#95483e', '#f7c2b0', '#f2988f', '#b57c75', '#c62f1e', '#6a1b17'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_member_status/out/get_data`, {lang: "EN"}, {
                withCredentials: true
            });

            console.log(response.data)
            if (response.data.result === 't') {
                const data = response.data.data;
                setRegularDepartmentData(data.get_regular_member_data);
                setNormalDepartmentData(data.get_normal_member_data);
                setOldDepartmentData(data.get_old_member_data);
                setForignerDepartmentData(data.get_forigner_member_data);
                setTotalData(data.get_total_member_data);

                // 정회원 그래프 데이터
                const transformedData = data.get_regular_member_data
                .filter(item => item.letterDepartName !== 'Total') // 'Total' 항목 필터링
                .map((item, index) => ({
                    name: item.letterDepartName,  // '분과' 제거
                    y: item.total,  // 'total' 값을 'y'로 설정
                    color: colorArray[index % colorArray.length]  // 색상 순환
                }));
                setRegularMemberData(transformedData);

                // 정회원 그래프 데이터
                const transformedNormalData = data.get_normal_member_data
                .filter(item => item.letterDepartName !== 'Total') // 'Total' 항목 필터링
                .map((item, index) => ({
                    name: item.letterDepartName,  // '분과' 제거
                    y: item.total,  // 'total' 값을 'y'로 설정
                    color: colorArray2[index % colorArray2.length]  // 색상 순환
                }));
                setNormalMemberData(transformedNormalData);

                
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const createChartOptions = (titleText, totleColor, memberCount, data) => {
        return {
            chart: {
                type: 'pie',
                height: isMobileView ? 100 + '%' : 700 + 'px',
                margin: [0, 0, 0, 0],
                options3d: {
                    enabled: true,
                    alpha: 50,
                    beta: 0
                },
            },
            title: {
                text: `
                <div className="${styles.chart_middle_box}">
                    <span className="${styles.chart_middle_title}">${titleText}</span>
                    <span className="${styles.chart_middle_cont}" style="color:${totleColor};">${memberCount}<span className="${styles.chart_middle_end}"></span></span>
                </div>
                 `,
                align: 'center',
                verticalAlign: 'middle',
                y: 35,
                l: 50,
                useHTML: true // HTML 사용을 명시
            },
            plotOptions: {
                pie: {
                    innerSize: isMobileView2 ? 150 : 200,
                    depth: 65,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name} {point.y}',
                        distance: 1, // 글씨를 안쪽으로 배치
                        style: {
                            fontSize: isMobileView ? '12px' : '22px',
                            color: '#000000',
                            fontWeight: 400,
                        },
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        alignTo: 'plotEdges', // 레이블을 차트 모서리에 맞춤
                        connectorPadding: 0
                    },
                    states: {
                        hover: {
                            enabled: false // 마우스 호버 시 효과 제거
                        },
                        inactive: {
                            enabled: false // 비활성화 상태 제거
                        }
                    },
                    allowPointSelect: false // 점 선택 비활성화 (선택 애니메이션 제거)
                }
            },
            tooltip: {
                enabled: false // 툴팁 비활성화
            },
            series: [{
                data: data
            }]
        };
    };

    // 전체합계
    const totalValue = totalData.length > 0 ? totalData[0].total : 0;

    // 정회원 차트 옵션 생성
    const regularMemberOptions = createChartOptions(
        'Member',
        '#e79911',
        regularMemberData.reduce((acc, item) => acc + item.y, 0), // 정회원 총합계 계산
        regularMemberData
    );

    // 일반회원 차트 옵션 생성
    const normalMemberOptions = createChartOptions(
        'Associate Member',
        '#c04617',
        normalMemberData.reduce((acc, item) => acc + item.y, 0), // 일반회원 총합계 계산
        normalMemberData
    );


    const rowClassName = (rowData) => {
        return rowData.letterDepartName === 'Total' ? 'table_last_row' : '';
    };



    return (
        <>
            <div className="components_wrap">

                
                <div className="cont_wrap">
                    <div className={styles.chart_wrap}>
                        <div className={styles.cont_tit_box}>
                            <span>Member</span>
                        </div>
                        <div className={styles.chart_box}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={regularMemberOptions}
                            />
                        </div>
                    </div>
                    <div className={styles.table_wrap}>
                        <DataTable value={regularDepartmentData} rowClassName={rowClassName}>
                            <Column field="letterDepartName" header="Division" />
                            <Column field="academic" header="Academia" />
                            <Column field="industry" header="Industry" />
                            <Column field="total" header="Total" />
                        </DataTable>
                    </div>
                    <div className={`${styles.chart_wrap} ${styles.p_t_100}`}>
                        <div className={styles.cont_tit_box}>
                            <span>Associate Member</span>
                        </div>
                        <div className={styles.chart_box}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={normalMemberOptions}
                            />
                        </div>
                    </div>
                    <div className={styles.table_wrap}>
                        <DataTable value={normalDepartmentData} rowClassName={rowClassName}>
                            <Column field="letterDepartName" header="Division" />
                            <Column field="academic" header="Academia" />
                            <Column field="industry" header="Industry" />
                            <Column field="total" header="Total" />
                        </DataTable>
                    </div>
                    <div className="flex_between">
                        <div className={styles.status_table_wrap_2}>
                            <div className={styles.p_r_5}>
                                <div className={styles.cont_tit_box}>
                                    <span>Emeritus Member</span>
                                </div>
                                <DataTable value={oldDepartmentData} rowClassName={rowClassName}>
                                    <Column className={styles.t_a_c} field="total" header="Total" />
                                </DataTable>
                            </div>
                        </div>
                        <div className={styles.status_table_wrap_2}>
                            <div className={styles.p_l_5}>
                                <div className={styles.cont_tit_box}>
                                    <span>Foreign Member</span>
                                </div>
                                <DataTable value={forignerDepartmentData} rowClassName={rowClassName}>
                                    <Column className={styles.t_a_c} field="total" header="Total" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className={styles.total_box}>
                        <span>Total <b>{totalValue.toLocaleString()}</b></span>
                    </div>
                    
                </div>
                
            </div>
        </>
    );
}

export default MembersIntro;