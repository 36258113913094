
import styles from './MyPage.module.scss';
import React, { useState, useEffect, useRef } from 'react';
import common from '../../../common';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { ko } from 'date-fns/locale';
import { FaCalendarDay } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function MyPage() {

    const navigate = useNavigate();

    const loginInfo = useSelector((state) => state.user.loginInfo);
    const loginIdx = loginInfo.idx;

    const { pToast, useAlert, useConfirm, useLoading, useDatePickerMonth, useModal } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent } = useConfirm();

    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const { pModal, ModalComponent } = useModal(); // 훅 사용


    const [formData, setFormData] = useState({
        thumbnail: [],//프로필이미지
        thumbnailDel: [],
        thumbnailFile: [],
        grade: '',//회원구분
        basegrade: '',//변경예정등급
        baseDate: '0000-00-00',//등급변경일
        baseDateData: null,
        reasonforResign: '',//탈퇴사유
        memberNumber: '',//회원번호
        gender: '남',//성별
        genderOption: [
            { value: '남', label: '남' },
            { value: '여', label: '여' }
        ],//성별
        birth: '0000-00-00',//생년월일
        birthData: null,
        nameKR: '',//이름(한글)
        nameEN: '',//이름(영문)
        nameHanja: '',//이름(한자)
        workField: '',//업부분야
        letterDepart: '',//제1소속분과
        letterDepartTwo: '',//제2소속분과
        migrationLetterDepart: '',//개편전분과
        committees: '',//상설위원회
        addressCode: '',//우편번호(한글)
        address: '',//주소1(한글)
        addressDetail: '',//주소2(한글)
        addressEN: '',//주소1(영문)
        addressDetailEN: '',//주소2(영문)
        affiliationCountry: '',//소속국가
        countryCode: '',//소속국가전화코드
        organizationKR: '',//소속1-기관(한글)
        deptKR: '',//소속1-부서(한글)
        positionKR: '',//소속1-직위(한글)
        organizationEN: '',//소속1-기관(영문)
        deptEN: '',//소속1-부서(영문)
        positionEN: '',//소속1-직위(영문)
        organizationTwoKR: '',//소속2-기관(한글)
        deptTwoKR: '',//소속2-부서(한글)
        positionTwoKR: '',//소속2-직위(한글)
        organizationTwoEN: '',//소속2-기관(영문)
        deptTwoEN: '',//소속2-부서(영문)
        positionTwoEN: '',//소속2-직위(영문)
        email: '',//이메일(로그인이메일)
        managerEmail: '',//연락담당자 이메일
        phone: '',//휴대폰
        phonePart1: '',
        phonePart2: '',
        phonePart3: '',
        companyCall: '',//직장번호
        companyCallPart1: '',
        companyCallPart2: '',
        companyCallPart3: '',
        homeCall: '',//자택번호
        homeCallPart1: '',
        homeCallPart2: '',
        homeCallPart3: '',
        managerPhone: '',//연락담당자 번호
        managerPhonePart1: '',
        managerPhonePart2: '',
        managerPhonePart3: '',
        etcCallName: '',//기타연락처 ex
        etcCall: '',//기타연락처
        etcCallPart1: '',
        etcCallPart2: '',
        etcCallPart3: '',
        website: '',//홈페이지 주소
        majorKR: '',//전공(한글)
        majorEN: '',//전공(영문)
        degreeInstitutionKR: '',//학위취득기관(한글)
        degreeInstitutionEN: '',//학위취득기관(영문)
        degreeKR: '',//학위(한글)
        degreeEN: '',//학위(영문)
        yearOfDegree: '',//학위취득연도
        schoolHistory: '',//학력
        career: '',//경력
        award: '',//상훈
        naekCareer: '',//NAEK 내 경력
        achievementKeyword: '',//전문분야키워드(5개)
        invite: '',//참여초청대상
        ex: '',//비고
        memberDate: '',//최초입회
        regularMemberDate: '',//정회원입회
        seniorMemberDate: '',//원로회원입회
        resignDate: '',//탈퇴일
    });

    const [selectedData, setSelectedData] = useState(null); 


    useEffect(() => {
        const fetchDetailData = async () => {
            try {
                pLoadingOn();
                const response = await axios.post(`${API_BASE_URL}/naek_my_page/in/get_detail`, { targetIdx: loginIdx }, {
                    withCredentials: true
                });
    

                console.log(response.data)
                if (response.data.result === 't') {
                    const data = response.data.data;
                    const phoneString = data?.phone || '';
                    const [phonePart1 = '', phonePart2 = '', phonePart3 = ''] = phoneString.split('-');

                    const homeCallString = data?.homeCall || '';
                    const [homeCallPart1 = '', homeCallPart2 = '', homeCallPart3 = ''] = homeCallString.split('-');

                    const companyCallString = data?.companyCall || '';
                    const [companyCallPart1 = '', companyCallPart2 = '', companyCallPart3 = ''] = companyCallString.split('-');

                    const managerPhoneString = data?.managerPhone || '';
                    const [managerPhonePart1 = '', managerPhonePart2 = '', managerPhonePart3 = ''] = managerPhoneString.split('-');

                    const etcCallString = data?.etcCall || '';
                    const [etcCallPart1 = '', etcCallPart2 = '', etcCallPart3 = ''] = etcCallString.split('-');

                    let birthData = null;
                    if (data?.birth && data.birth !== '0000-00-00') {
                        const parts = data.birth.split('-');
                        if (parts.length === 3) {
                            const [year, month, day] = parts.map(part => parseInt(part, 10));
                            birthData = new Date(year, month - 1, day);
                        }
                    }

                    let baseDateData = null;
                    if (data?.baseDate && data.baseDate !== '0000-00-00') {
                        const parts = data.baseDate.split('-');
                        if (parts.length === 3) {
                            const [year, month, day] = parts.map(part => parseInt(part, 10));
                            baseDateData = new Date(year, month - 1, day);
                        }
                    }

                    let memberDateData = null;
                    if (data?.memberDate && data.memberDate !== '0000-00-00') {
                        const parts = data.memberDate.split('-');
                        if (parts.length === 3) {
                            const [year, month, day] = parts.map(part => parseInt(part, 10));
                            memberDateData = new Date(year, month - 1, day);
                        }
                    }

                    let regularMemberDateData = null;
                    if (data?.regularMemberDate && data.regularMemberDate !== '0000-00-00') {
                        const parts = data.regularMemberDate.split('-');
                        if (parts.length === 3) {
                            const [year, month, day] = parts.map(part => parseInt(part, 10));
                            regularMemberDateData = new Date(year, month - 1, day);
                        }
                    }

                    let seniorMemberDateData = null;
                    if (data?.seniorMemberDate && data.seniorMemberDate !== '0000-00-00') {
                        const parts = data.seniorMemberDate.split('-');
                        if (parts.length === 3) {
                            const [year, month, day] = parts.map(part => parseInt(part, 10));
                            seniorMemberDateData = new Date(year, month - 1, day);
                        }
                    }

                    let resignDateData = null;
                    if (data?.resignDate && data.resignDate !== '0000-00-00') {
                        const parts = data.resignDate.split('-');
                        if (parts.length === 3) {
                            const [year, month, day] = parts.map(part => parseInt(part, 10));
                            resignDateData = new Date(year, month - 1, day);
                        }
                    }

                    setFormData({
                        thumbnail: data?.thumbnail || [],
                        thumbnailDel: [],
                        thumbnailFile: [],
                        grade: data?.grade || '',//회원구분
                        basegrade: data?.basegrade || '',//변경예정등급
                        baseDate: data?.baseDate || '0000-00-00',//등급변경일
                        baseDateData: baseDateData,
                        reasonforResign: data?.reasonforResign || '',//탈퇴사유
                        memberNumber: data?.memberNumber || '',//회원번호
                        gender: data?.gender || '남',//성별
                        genderOption: [
                            { value: '남', label: '남' },
                            { value: '여', label: '여' }
                        ],//성별
                        birth: data?.birth || '0000-00-00',//생년월일
                        birthData: birthData,
                        nameKR: data?.nameKR || '',//이름(한글)
                        nameEN: data?.nameEN || '',//이름(영문)
                        nameHanja: data?.nameHanja || '',//이름(한자)
                        workField: data?.workField || '',//업부분야
                        letterDepart: data?.letterDepart || '',//제1소속분과
                        letterDepartTwo: data?.letterDepartTwo || '',//제2소속분과
                        migrationLetterDepart: data?.migrationLetterDepart || '',//개편전분과
                        committees: data?.committees || '',//상설위원회
                        addressCode: data?.addressCode || '',//우편번호(한글)
                        address: data?.address || '',//주소1(한글)
                        addressDetail: data?.addressDetail || '',//주소2(한글)
                        addressEN: data?.addressEN || '',//주소1(영문)
                        addressDetailEN: data?.addressDetailEN || '',//주소2(영문)
                        affiliationCountry: data?.affiliationCountry || '',//소속국가
                        countryCode: data?.countryCode || '',//소속국가전화코드
                        organizationKR: data?.organizationKR || '',//소속1-기관(한글)
                        deptKR: data?.deptKR || '',//소속1-부서(한글)
                        positionKR: data?.positionKR || '',//소속1-직위(한글)
                        organizationEN: data?.organizationEN || '',//소속1-기관(영문)
                        deptEN: data?.deptEN || '',//소속1-부서(영문)
                        positionEN: data?.positionEN || '',//소속1-직위(영문)
                        organizationTwoKR: data?.organizationTwoKR || '',//소속2-기관(한글)
                        deptTwoKR: data?.deptTwoKR || '',//소속2-부서(한글)
                        positionTwoKR: data?.positionTwoKR || '',//소속2-직위(한글)
                        organizationTwoEN: data?.organizationTwoEN || '',//소속2-기관(영문)
                        deptTwoEN: data?.deptTwoEN || '',//소속2-부서(영문)
                        positionTwoEN: data?.positionTwoEN || '',//소속2-직위(영문)
                        email: data?.email || '',//이메일(로그인이메일)
                        managerEmail: data?.managerEmail || '',//연락담당자 이메일
                        phone: data?.phone || '',//휴대폰
                        phonePart1,
                        phonePart2,
                        phonePart3,
                        companyCall: data?.companyCall || '',//직장번호
                        companyCallPart1,
                        companyCallPart2,
                        companyCallPart3,
                        homeCall: data?.homeCall || '',//자택번호
                        homeCallPart1,
                        homeCallPart2,
                        homeCallPart3,
                        managerPhone: data?.managerPhone || '',//연락담당자 번호
                        managerPhonePart1,
                        managerPhonePart2,
                        managerPhonePart3,
                        etcCallName: data?.etcCallName || '',//기타연락처 ex
                        etcCall: data?.etcCall || '',//기타연락처
                        etcCallPart1,
                        etcCallPart2,
                        etcCallPart3,
                        website: data?.website || '',//홈페이지 주소
                        majorKR: data?.majorKR || '',//전공(한글)
                        majorEN: data?.majorEN || '',//전공(영문)
                        degreeInstitutionKR: data?.degreeInstitutionKR || '',//학위취득기관(한글)
                        degreeInstitutionEN: data?.degreeInstitutionEN || '',//학위취득기관(영문)
                        degreeKR: data?.degreeKR || '',//학위(한글)
                        degreeEN: data?.degreeEN || '',//학위(영문)
                        yearOfDegree: data?.yearOfDegree || '',//학위취득연도
                        schoolHistory: data?.schoolHistory || '',//학력
                        career: data?.career || '',//경력
                        award: data?.award || '',//상훈
                        naekCareer: data?.naekCareer || '',//NAEK 내 경력
                        achievementKeyword: data?.achievementKeyword || '',//전문분야키워드(5개)
                        invite: data?.invite || '',//참여초청대상
                        ex: data?.ex || '',//비고
                        memberDate: data?.memberDate || '0000-00-00',//최초입회
                        memberDateData: memberDateData,
                        regularMemberDate: data?.regularMemberDate || '0000-00-00',//정회원입회
                        regularMemberDateData: regularMemberDateData,
                        seniorMemberDate: data?.seniorMemberDate || '0000-00-00',//원로회원입회
                        seniorMemberDateData: seniorMemberDateData,
                        resignDate: data?.resignDate || '0000-00-00',//탈퇴일
                        resignDateData: resignDateData,
                    });
                    setSelectedData({
                        ...data,
                        thumbnail: data?.thumbnail || [],
                        thumbnailDel: [],
                        thumbnailFile: [],
                        grade: data?.grade || '',//회원구분
                        basegrade: data?.basegrade || '',//변경예정등급
                        baseDate: data?.baseDate || '0000-00-00',//등급변경일
                        baseDateData: baseDateData,
                        reasonforResign: data?.reasonforResign || '',//탈퇴사유
                        memberNumber: data?.memberNumber || '',//회원번호
                        gender: data?.gender || 'M',//성별
                        genderOption: [
                            { value: 'M', label: '남' },
                            { value: 'F', label: '여' }
                        ],//성별
                        birth: data?.birth || '0000-00-00',//생년월일
                        birthData: birthData,
                        nameKR: data?.nameKR || '',//이름(한글)
                        nameEN: data?.nameEN || '',//이름(영문)
                        nameHanja: data?.nameHanja || '',//이름(한자)
                        workField: data?.workField || '',//업부분야
                        letterDepart: data?.letterDepart || '',//제1소속분과
                        letterDepartTwo: data?.letterDepartTwo || '',//제2소속분과
                        migrationLetterDepart: data?.migrationLetterDepart || '',//개편전분과
                        committees: data?.committees || '',//상설위원회
                        addressCode: data?.addressCode || '',//우편번호(한글)
                        address: data?.address || '',//주소1(한글)
                        addressDetail: data?.addressDetail || '',//주소2(한글)
                        addressEN: data?.addressEN || '',//주소1(영문)
                        addressDetailEN: data?.addressDetailEN || '',//주소2(영문)
                        affiliationCountry: data?.affiliationCountry || '',//소속국가
                        countryCode: data?.countryCode || '',//소속국가전화코드
                        organizationKR: data?.organizationKR || '',//소속1-기관(한글)
                        deptKR: data?.deptKR || '',//소속1-부서(한글)
                        positionKR: data?.positionKR || '',//소속1-직위(한글)
                        organizationEN: data?.organizationEN || '',//소속1-기관(영문)
                        deptEN: data?.deptEN || '',//소속1-부서(영문)
                        positionEN: data?.positionEN || '',//소속1-직위(영문)
                        organizationTwoKR: data?.organizationTwoKR || '',//소속2-기관(한글)
                        deptTwoKR: data?.deptTwoKR || '',//소속2-부서(한글)
                        positionTwoKR: data?.positionTwoKR || '',//소속2-직위(한글)
                        organizationTwoEN: data?.organizationTwoEN || '',//소속2-기관(영문)
                        deptTwoEN: data?.deptTwoEN || '',//소속2-부서(영문)
                        positionTwoEN: data?.positionTwoEN || '',//소속2-직위(영문)
                        email: data?.email || '',//이메일(로그인이메일)
                        managerEmail: data?.managerEmail || '',//연락담당자 이메일
                        phone: data?.phone || '',//휴대폰
                        phonePart1,
                        phonePart2,
                        phonePart3,
                        companyCall: data?.companyCall || '',//직장번호
                        companyCallPart1,
                        companyCallPart2,
                        companyCallPart3,
                        homeCall: data?.homeCall || '',//자택번호
                        homeCallPart1,
                        homeCallPart2,
                        homeCallPart3,
                        managerPhone: data?.managerPhone || '',//연락담당자 번호
                        managerPhonePart1,
                        managerPhonePart2,
                        managerPhonePart3,
                        etcCallName: data?.etcCallName || '',//기타연락처 ex
                        etcCall: data?.etcCall || '',//기타연락처
                        etcCallPart1,
                        etcCallPart2,
                        etcCallPart3,
                        website: data?.website || '',//홈페이지 주소
                        majorKR: data?.majorKR || '',//전공(한글)
                        majorEN: data?.majorEN || '',//전공(영문)
                        degreeInstitutionKR: data?.degreeInstitutionKR || '',//학위취득기관(한글)
                        degreeInstitutionEN: data?.degreeInstitutionEN || '',//학위취득기관(영문)
                        degreeKR: data?.degreeKR || '',//학위(한글)
                        degreeEN: data?.degreeEN || '',//학위(영문)
                        yearOfDegree: data?.yearOfDegree || '',//학위취득연도
                        schoolHistory: data?.schoolHistory || '',//학력
                        career: data?.career || '',//경력
                        award: data?.award || '',//상훈
                        naekCareer: data?.naekCareer || '',//NAEK 내 경력
                        achievementKeyword: data?.achievementKeyword || '',//전문분야키워드(5개)
                        invite: data?.invite || '',//참여초청대상
                        ex: data?.ex || '',//비고
                        memberDate: data?.memberDate || '0000-00-00',//최초입회
                        memberDateData: memberDateData,
                        regularMemberDate: data?.regularMemberDate || '0000-00-00',//정회원입회
                        regularMemberDateData: regularMemberDateData,
                        seniorMemberDate: data?.seniorMemberDate || '0000-00-00',//원로회원입회
                        seniorMemberDateData: seniorMemberDateData,
                        resignDate: data?.resignDate || '0000-00-00',//탈퇴일
                        resignDateData: resignDateData,
                    });
                } else {
                    navigate(`/login`);
                    pAlert(response.data.msg);
                }
            } catch (error) {
                console.error('Data fetching failed:', error);
            } finally {
                pLoadingOff();
            }
        }
        fetchDetailData();
        
    }, []);



    useEffect(() => {
        console.log('formData',formData);
    }, [formData]);











    const imageInputRef = useRef(null);
    // 썸네일 변경
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return; // 파일이 없을 경우 함수를 종료
        pLoadingOn();

        // 이미지 파일 타입 체크
        if (!file.type.startsWith('image/')) {
            pLoadingOff();
            pToast('유효하지 않은 이미지입니다.');
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result && reader.result.startsWith('data:image/')) {
                const img = new Image();
                img.onerror = () => {
                    pLoadingOff();
                    pToast('유효하지 않은 이미지입니다.');
                    return;
                };
                img.onload = () => {
                    // 이미지가 정상적으로 로드되면 formData.thumbnail 업데이트
                    setFormData((prevState) => ({
                        ...prevState,
                        thumbnail: [{ name: file.name, url: reader.result }],
                        thumbnailFile: file,
                        thumbnailDel: [],
                    }));
                    pLoadingOff(); // 로드 완료 후 로딩 중지
                };
                img.src = reader.result; // 이미지 소스로 reader.result 설정
            } else {
                pLoadingOff();
                pToast('유효하지 않은 이미지입니다.');
                return;
            }
        };
        reader.onerror = () => {
            pLoadingOff();
            pToast('유효하지 않은 이미지입니다.');
            return;
        };
        reader.readAsDataURL(file);

        // 같은 파일을 다시 업로드할 수 있도록 input 초기화
        e.target.value = '';
    };





    const handleImageDelete = () => {
        setFormData((prevState) => {
            // selectedData.thumbnail이 빈 배열인지 확인
            const newThumbnailDel = selectedData?.thumbnail.length > 0 ? prevState.thumbnail : prevState.thumbnailDel;

            return {
                ...prevState,
                thumbnailDel: newThumbnailDel,
                thumbnail: [],
                thumbnailFile: []
            };
        });
        // 같은 파일을 다시 업로드할 수 있도록 input 초기화
        if (imageInputRef.current) {
            imageInputRef.current.value = '';
        }
    };


    // 날짜 변경
    const handleDateChange = (date, fieldName) => {
        if (!date) {
            setFormData(prevFormData => ({
                ...prevFormData,
                [fieldName + 'Data']: null,
                [fieldName]: "0000-00-00"
            }));
            return;
        }

        // 로컬 시간대를 무시하고 날짜를 정확히 처리하기 위해 수동으로 연도, 월, 날짜를 설정
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        // 로컬 시간대를 고려하지 않고 날짜를 생성하기 위해 UTC로 변환
        const localDate = new Date(Date.UTC(year, month, day));

        // ISO 형식으로 변환 (UTC 시간 기준에서 잘라내므로 한국 시간대에 영향받지 않음)
        const formattedDate = localDate.toISOString().slice(0, 10);

        setFormData(prevFormData => ({
            ...prevFormData,
            [fieldName + 'Data']: localDate,
            [fieldName]: formattedDate
        }));
    };





    // 휴대폰번호
    const handleNumbersOnly = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            e.target.value = value;
        } else {
            e.target.value = e.target.value.slice(0, -1);
        }
    };





    //주소검색
    const handleAddressSearch = () => {
        new window.daum.Postcode({
            oncomplete: function (data) {
                let fullAddress = data.address;
                let extraAddress = '';

                if (data.addressType === 'R') {
                    if (data.bname !== '') {
                        extraAddress += data.bname;
                    }
                    if (data.buildingName !== '') {
                        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
                    }
                    fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
                }

                setFormData({
                    ...formData,
                    addressCode: data.zonecode,
                    address: fullAddress
                });
            }
        }).open();
    };




    // achievementKeyword를 분리해서 배열로 변환
    const keywords = formData.achievementKeyword ? formData.achievementKeyword.split('|@|') : Array(5).fill('');

    // 키워드 변경 핸들러
    const handleKeywordChange = (index, value) => {
        // 해당 인덱스의 키워드를 업데이트
        const updatedKeywords = [...keywords];
        updatedKeywords[index] = value;

        // 배열을 다시 문자열로 결합
        const updatedAchievementKeyword = updatedKeywords.join('|@|');

        // 업데이트된 achievementKeyword를 formData에 설정
        setFormData({
            ...formData,
            achievementKeyword: updatedAchievementKeyword
        });
    };





























    /////////////////////////////////수정/////////////////////////////////

    let newFormData = {};
    let newUpdateFormData = new FormData();
    const handleUpdateClick = async () => {
        if (!formData || !selectedData) {
            pToast('시스템 오류, 관리자에게 문의하세요.');
            return;
        }

        const excludeKeys = [
            'genderOption', 'baseDateData', 'birthData',
            'phonePart1', 'phonePart2', 'phonePart3',
            'companyCallPart1', 'companyCallPart2', 'companyCallPart3',
            'homeCallPart1', 'homeCallPart2', 'homeCallPart3',
            'managerPhonePart1', 'managerPhonePart2', 'managerPhonePart3',
            'etcCallPart1', 'etcCallPart2', 'etcCallPart3',
            'memberDateData', 'regularMemberDateData', 'seniorMemberDateData', 'resignDateData', 'thumbnail'
        ];

        for (let key of Object.keys(formData)) {
            if (excludeKeys.includes(key)) {
                continue; // 비교에서 제외
            }

            if (key === 'grade' && formData[key] !== selectedData[key]) {
                if (formData.baseDate === '0000-00-00' || formData.baseDate === null) {
                    pAlert('등급변경시엔 변경예정일을 선택해주세요.');
                    return; // 함수를 종료하여 더 이상 진행되지 않도록 함
                }
            }
            if ((key === 'thumbnailDel') && Array.isArray(formData[key])) {
                if (formData[key].length > 0) {
                    newFormData[key] = formData[key];
                }
            } else if (key === 'thumbnailFile' && formData[key] instanceof File) {
                newFormData[key] = formData[key];
            } else if (formData[key] !== selectedData[key]) {
                newFormData[key] = formData[key];
            }
        }

        newUpdateFormData.append('targetIdx', selectedData.idx);

        Object.entries(newFormData).forEach(([key, value]) => {
            if (key === 'thumbnailFile' && Array.isArray(value)) {
                value.forEach(file => {
                    newUpdateFormData.append(key, file);
                });
            } else if (Array.isArray(value)) {
                newUpdateFormData.append(key, JSON.stringify(value));
            } else {
                newUpdateFormData.append(key, value);
            }
        });

        if (Array.from(newUpdateFormData.keys()).filter(key => key !== 'targetIdx').length === 0) {
            pToast('변경된 내용이 없습니다.');
            return;
        }

        console.log('------------------폼데이타시작------------------');
        for (let [key, value] of newUpdateFormData.entries()) {
            console.log(`${key}: ${value instanceof File ? value.name : value}`);
        }
        console.log('------------------폼데이타끝------------------');







        pConfirm(
            '수정',
            <>
                <div>내용을 수정 하시겠습니까?</div>
            </>,
            '400px',
            'auto',
            async () => {
                try {
                    pLoadingOn();

                    const response = await axios.post(`${API_BASE_URL}/naek_my_page/in/data_update`, newUpdateFormData, {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (response.data.result === 't') {
                        console.log('리턴데이타' + response.data.data);

                        setSelectedData(prevFormData => {
                            const updatedData = {
                                ...prevFormData,
                                ...newFormData,
                            };
                            return updatedData;
                        });

                        setFormData(prevFormData => ({
                            ...prevFormData,
                        }));
                        pAlert('수정이 완료되었습니다.');
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    console.error('등록 실패:', error);
                    pAlert('등록에 실패했습니다. 다시 시도해 주세요.');
                } finally {
                    pLoadingOff();
                }
            }
        );
    };






    return (
        <>
            <ModalComponent />
            <AlertComponent />
            <ConfirmComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <div className={styles.my_page_wrap}>









                    <div className={styles.input_image_wrap}>
                        {formData.thumbnail && formData.thumbnail.length > 0 ? (
                            <img src={formData.thumbnail[0].url} className={styles.preview_image} />
                        ) : (
                            <img src='/img/profile.png' alt='' className={styles.preview_image} />
                        )}
                        <div className={`${styles.input_image_btn_box} flex_center`}>
                            <label className={styles.input_file_button} htmlFor="input-image">
                                업로드
                            </label>
                            <input
                                type="file"
                                id="input-image"
                                accept="image/*"
                                onChange={handleImageChange}
                                ref={imageInputRef}
                                style={{ display: 'none' }}
                            />
                            <button className={styles.input_file_button_del} onClick={handleImageDelete}>
                                삭제
                            </button>
                        </div>
                    </div>




                


                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_tit_box}>
                            <span>이메일</span>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <input
                                type="text"
                                className={styles.p_input}
                                value={formData.email || ''}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                name="email"
                                readOnly
                            />
                        </div>
                    </div>












                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_cont_box}>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>성명(한글)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.nameKR || ''}
                                        onChange={(e) => setFormData({ ...formData, nameKR: e.target.value })}
                                        name="nameKR"
                                    />
                                </div>
                            </div>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>성명(영어)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.nameEN || ''}
                                        onChange={(e) => setFormData({ ...formData, nameEN: e.target.value })}
                                        name="nameEN"
                                    />
                                </div>
                            </div>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>성명(한자)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.nameHanja || ''}
                                        onChange={(e) => setFormData({ ...formData, nameHanja: e.target.value })}
                                        name="nameHanja"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_tit_box}>
                            <span>생년월일</span>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <DatePicker
                                className={styles.p_ip}
                                dateFormat="yyyy-MM-dd"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selected={formData?.birthData}
                                onChange={(date) => handleDateChange(date, 'birth')}
                                placeholderText="0000-00-00"
                            />
                            <FaCalendarDay className="calendarIcon" />
                        </div>
                    </div>






                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_tit_box}>
                            <span>성별</span>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <Select
                                options={formData?.genderOption}
                                value={Array.isArray(formData?.genderOption) ? formData.genderOption.find(option => option.value === formData.gender) : null}
                                onChange={(option) => setFormData({ ...formData, gender: option.value })}
                                className={styles.p_select}
                            />
                        </div>
                    </div>








                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_tit_box}>
                            <span>소속1</span>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>기관(한글)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.organizationKR || ''}
                                        onChange={(e) => setFormData({ ...formData, organizationKR: e.target.value })}
                                        name="organizationKR"
                                    />
                                </div>
                            </div>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>부서(한글)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.deptKR || ''}
                                        onChange={(e) => setFormData({ ...formData, deptKR: e.target.value })}
                                        name="deptKR"
                                    />
                                </div>
                            </div>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>직위(한글)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.positionKR || ''}
                                        onChange={(e) => setFormData({ ...formData, positionKR: e.target.value })}
                                        name="positionKR"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>기관(영문)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.organizationEN || ''}
                                        onChange={(e) => setFormData({ ...formData, organizationEN: e.target.value })}
                                        name="organizationEN"
                                    />
                                </div>
                            </div>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>부서(영문)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.deptEN || ''}
                                        onChange={(e) => setFormData({ ...formData, deptEN: e.target.value })}
                                        name="deptEN"
                                    />
                                </div>
                            </div>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>직위(영문)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.positionEN || ''}
                                        onChange={(e) => setFormData({ ...formData, positionEN: e.target.value })}
                                        name="positionEN"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>










                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_tit_box}>
                            <span>소속2</span>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>기관(한글)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.organizationTwoKR || ''}
                                        onChange={(e) => setFormData({ ...formData, organizationTwoKR: e.target.value })}
                                        name="organizationTwoKR"
                                    />
                                </div>
                            </div>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>부서(한글)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.deptTwoKR || ''}
                                        onChange={(e) => setFormData({ ...formData, deptTwoKR: e.target.value })}
                                        name="deptTwoKR"
                                    />
                                </div>
                            </div>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>직위(한글)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.positionTwoKR || ''}
                                        onChange={(e) => setFormData({ ...formData, positionTwoKR: e.target.value })}
                                        name="positionTwoKR"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>기관(영문)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.organizationTwoEN || ''}
                                        onChange={(e) => setFormData({ ...formData, organizationTwoEN: e.target.value })}
                                        name="organizationTwoEN"
                                    />
                                </div>
                            </div>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>부서(영문)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.deptTwoEN || ''}
                                        onChange={(e) => setFormData({ ...formData, deptTwoEN: e.target.value })}
                                        name="deptTwoEN"
                                    />
                                </div>
                            </div>
                            <div className={styles.member_de_update_3_box}>
                                <div className={styles.member_de_update_tit_box}>
                                    <span>직위(영문)</span>
                                </div>
                                <div className={styles.member_de_update_cont_box}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={formData.positionTwoEN || ''}
                                        onChange={(e) => setFormData({ ...formData, positionTwoEN: e.target.value })}
                                        name="positionTwoEN"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_3_box}>
                            <div className={styles.member_de_update_tit_box}>
                                <span>휴대폰</span>
                            </div>
                            <div className={`${styles.member_de_update_cont_box} ${styles.phone_box}`}>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.phonePart1}
                                    onChange={(e) => {
                                        const newPhonePart1 = e.target.value;
                                        const newCall = `${newPhonePart1}-${formData.phonePart2}-${formData.phonePart3}`;
                                        setFormData({ ...formData, phonePart1: newPhonePart1, phone: newCall });
                                    }}
                                    name="phonePart1"
                                    onInput={handleNumbersOnly}
                                />
                                <span>-</span>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.phonePart2}
                                    onChange={(e) => {
                                        const newPhonePart2 = e.target.value;
                                        const newCall = `${formData.phonePart1}-${newPhonePart2}-${formData.phonePart3}`;
                                        setFormData({ ...formData, phonePart2: newPhonePart2, phone: newCall });
                                    }}
                                    name="phonePart2"
                                    onInput={handleNumbersOnly}
                                />
                                <span>-</span>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.phonePart3}
                                    onChange={(e) => {
                                        const newPhonePart3 = e.target.value;
                                        const newCall = `${formData.phonePart1}-${formData.phonePart2}-${newPhonePart3}`;
                                        setFormData({ ...formData, phonePart3: newPhonePart3, phone: newCall });
                                    }}
                                    name="phonePart3"
                                    onInput={handleNumbersOnly}
                                />
                            </div>
                        </div>
                    </div>


                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_3_box}>
                            <div className={styles.member_de_update_tit_box}>
                                <span>직장번호</span>
                            </div>
                            <div className={`${styles.member_de_update_cont_box} ${styles.phone_box}`}>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.companyCallPart1}
                                    onChange={(e) => {
                                        const newCompanyCallPart1 = e.target.value;
                                        const newCall = `${newCompanyCallPart1}-${formData.companyCallPart2}-${formData.companyCallPart3}`;
                                        setFormData({ ...formData, companyCallPart1: newCompanyCallPart1, companyCall: newCall });
                                    }}
                                    name="companyCallPart1"
                                    onInput={handleNumbersOnly}
                                />
                                <span>-</span>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.companyCallPart2}
                                    onChange={(e) => {
                                        const newCompanyCallPart2 = e.target.value;
                                        const newCall = `${formData.companyCallPart1}-${newCompanyCallPart2}-${formData.companyCallPart3}`;
                                        setFormData({ ...formData, companyCallPart2: newCompanyCallPart2, companyCall: newCall });
                                    }}
                                    name="companyCallPart2"
                                    onInput={handleNumbersOnly}
                                />
                                <span>-</span>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.companyCallPart3}
                                    onChange={(e) => {
                                        const newCompanyCallPart3 = e.target.value;
                                        const newCall = `${formData.companyCallPart1}-${formData.companyCallPart2}-${newCompanyCallPart3}`;
                                        setFormData({ ...formData, companyCallPart3: newCompanyCallPart3, companyCall: newCall });
                                    }}
                                    name="companyCallPart3"
                                    onInput={handleNumbersOnly}
                                />
                            </div>
                        </div>
                    </div>


                    

                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_3_box}>
                            <div className={styles.member_de_update_tit_box}>
                                <span>자택번호</span>
                            </div>
                            <div className={`${styles.member_de_update_cont_box} ${styles.phone_box}`}>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.homeCallPart1}
                                    onChange={(e) => {
                                        const newManagerPhonePart1 = e.target.value;
                                        const newCall = `${newManagerPhonePart1}-${formData.homeCallPart2}-${formData.homeCallPart3}`;
                                        setFormData({ ...formData, homeCallPart1: newManagerPhonePart1, homeCall: newCall });
                                    }}
                                    name="homeCallPart1"
                                    onInput={handleNumbersOnly}
                                />
                                <span>-</span>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.homeCallPart2}
                                    onChange={(e) => {
                                        const newManagerPhonePart2 = e.target.value;
                                        const newCall = `${formData.homeCallPart1}-${newManagerPhonePart2}-${formData.homeCallPart3}`;
                                        setFormData({ ...formData, homeCallPart2: newManagerPhonePart2, homeCall: newCall });
                                    }}
                                    name="homeCallPart2"
                                    onInput={handleNumbersOnly}
                                />
                                <span>-</span>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.homeCallPart3}
                                    onChange={(e) => {
                                        const newManagerPhonePart3 = e.target.value;
                                        const newCall = `${formData.homeCallPart1}-${formData.homeCallPart2}-${newManagerPhonePart3}`;
                                        setFormData({ ...formData, homeCallPart3: newManagerPhonePart3, homeCall: newCall });
                                    }}
                                    name="homeCallPart3"
                                    onInput={handleNumbersOnly}
                                />
                            </div>
                        </div>
                    </div>




                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_3_box}>
                            <div className={styles.member_de_update_tit_box}>
                                <span>연락담당자 번호</span>
                            </div>
                            <div className={`${styles.member_de_update_cont_box} ${styles.phone_box}`}>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.managerPhonePart1}
                                    onChange={(e) => {
                                        const newManagerPhonePart1 = e.target.value;
                                        const newCall = `${newManagerPhonePart1}-${formData.managerPhonePart2}-${formData.managerPhonePart3}`;
                                        setFormData({ ...formData, managerPhonePart1: newManagerPhonePart1, managerPhone: newCall });
                                    }}
                                    name="managerPhonePart1"
                                    onInput={handleNumbersOnly}
                                />
                                <span>-</span>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.managerPhonePart2}
                                    onChange={(e) => {
                                        const newManagerPhonePart2 = e.target.value;
                                        const newCall = `${formData.managerPhonePart1}-${newManagerPhonePart2}-${formData.managerPhonePart3}`;
                                        setFormData({ ...formData, managerPhonePart2: newManagerPhonePart2, managerPhone: newCall });
                                    }}
                                    name="managerPhonePart2"
                                    onInput={handleNumbersOnly}
                                />
                                <span>-</span>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.managerPhonePart3}
                                    onChange={(e) => {
                                        const newManagerPhonePart3 = e.target.value;
                                        const newCall = `${formData.managerPhonePart1}-${formData.managerPhonePart2}-${newManagerPhonePart3}`;
                                        setFormData({ ...formData, managerPhonePart3: newManagerPhonePart3, managerPhone: newCall });
                                    }}
                                    name="managerPhonePart3"
                                    onInput={handleNumbersOnly}
                                />
                            </div>
                        </div>
                    </div>





                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_tit_box}>
                            <span>연락담당자 이메일</span>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <input
                                type="text"
                                className={styles.p_input}
                                value={formData.managerEmail || ''}
                                onChange={(e) => setFormData({ ...formData, managerEmail: e.target.value })}
                                name="managerEmail"
                            />
                        </div>
                    </div>






                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_tit_box}>
                            <span>기타연락처 ex</span>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <input
                                type="text"
                                className={styles.p_input}
                                value={formData.etcCallName || ''}
                                onChange={(e) => setFormData({ ...formData, etcCallName: e.target.value })}
                                name="etcCallName"
                                placeholder='ex)비서 휴대폰'
                            />
                        </div>
                    </div>



                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_3_box}>
                            <div className={styles.member_de_update_tit_box}>
                                <span>기타연락처</span>
                            </div>
                            <div className={`${styles.member_de_update_cont_box} ${styles.phone_box}`}>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.etcCallPart1}
                                    onChange={(e) => {
                                        const newEtcCallPart1 = e.target.value;
                                        const newCall = `${newEtcCallPart1}-${formData.etcCallPart2}-${formData.etcCallPart3}`;
                                        setFormData({ ...formData, etcCallPart1: newEtcCallPart1, etcCall: newCall });
                                    }}
                                    name="etcCallPart1"
                                    onInput={handleNumbersOnly}
                                />
                                <span>-</span>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.etcCallPart2}
                                    onChange={(e) => {
                                        const newEtcCallPart2 = e.target.value;
                                        const newCall = `${formData.etcCallPart1}-${newEtcCallPart2}-${formData.etcCallPart3}`;
                                        setFormData({ ...formData, etcCallPart2: newEtcCallPart2, etcCall: newCall });
                                    }}
                                    name="etcCallPart2"
                                    onInput={handleNumbersOnly}
                                />
                                <span>-</span>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.etcCallPart3}
                                    onChange={(e) => {
                                        const newEtcCallPart3 = e.target.value;
                                        const newCall = `${formData.etcCallPart1}-${formData.etcCallPart2}-${newEtcCallPart3}`;
                                        setFormData({ ...formData, etcCallPart3: newEtcCallPart3, etcCall: newCall });
                                    }}
                                    name="etcCallPart3"
                                    onInput={handleNumbersOnly}
                                />
                            </div>
                        </div>
                    </div>



                    <div className={styles.member_update_box_2}>
                        <div className={styles.member_de_update_box}>
                            <div className={styles.member_de_update_tit_box}>
                                <span>우편번호</span>
                            </div>
                            <div className={`${styles.member_de_update_cont_box} ${styles.address_box}`}>
                                <input
                                    type="text"
                                    className={`${styles.p_input} ${styles.de_address_code_input}`}
                                    value={formData.addressCode || ''}
                                    readOnly
                                />
                                <button className={`p_btn ${styles.address_btn}`} onClick={handleAddressSearch}>주소검색</button>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.address}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>


                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_box}>
                            <div className={`${styles.member_de_update_cont_box}`}>
                                <input
                                    type="text"
                                    className={styles.p_input}
                                    value={formData.addressDetail || ''}
                                    onChange={(e) => setFormData({ ...formData, addressDetail: e.target.value })}
                                    name="addressDetail"
                                    placeholder='상세주소'
                                />
                            </div>
                        </div>
                    </div>


                    


                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_tit_box}>
                            <span>전문분야 키워드</span>
                        </div>
                        <div className={styles.member_de_update_3_box}>
                            {keywords.slice(0, 5).map((keyword, index) => (
                                <div className={styles.member_de_update_cont_box} key={index}>
                                    <input
                                        type="text"
                                        className={styles.p_input}
                                        value={keyword}
                                        onChange={(e) => handleKeywordChange(index, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>






                    










                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_tit_box}>
                            <span>경력</span>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <textarea
                                name="career"
                                className={styles.p_textarea}
                                value={formData?.career || ''}
                                onChange={(e) => setFormData({ ...formData, career: e.target.value })}
                            />
                        </div>
                    </div>










                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_tit_box}>
                            <span>상훈</span>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <textarea
                                name="award"
                                className={styles.p_textarea}
                                value={formData?.award || ''}
                                onChange={(e) => setFormData({ ...formData, award: e.target.value })}
                            />
                        </div>
                    </div>






                    <div className={styles.member_update_box}>
                        <div className={styles.member_de_update_tit_box}>
                            <span>학력</span>
                        </div>
                        <div className={styles.member_de_update_cont_box}>
                            <textarea
                                name="schoolHistory"
                                className={styles.p_textarea}
                                value={formData?.schoolHistory || ''}
                                onChange={(e) => setFormData({ ...formData, schoolHistory: e.target.value })}
                            />
                        </div>
                    </div>


                    
                    

                    <div className={styles.update_btn_box}>
                        <button
                            className={`p_btn ${styles.update_btn}`}
                            onClick={handleUpdateClick}
                        >저장하기</button>
                    </div>


                    









































                </div>
            </div>
        </>
    );
}

export default MyPage;