
import styles from './MemberDepart.module.scss';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import {TableList} from "../../../components";
import common from '../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function MemberDepart() {

    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageid = queryParams.get('pageid');


    const [selectedData, setSelectedData] = useState([]);
    const [data , setData] = useState([]);

    const [data1 , setData1] = useState([]);
    const [data2 , setData2] = useState([]);
    const [data3 , setData3] = useState([]);
    const [data4 , setData4] = useState([]);
    const [data5 , setData5] = useState([]);
    const [data6 , setData6] = useState([]);
    const [data7 , setData7] = useState([]);
    const [data8 , setData8] = useState([]);

    const categoryData = ['60','61','62','63','64','65','66','67'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_table`, {categoryData:categoryData}, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const allData = response.data.data;

                const newData1 = [];
                const newData2 = [];
                const newData3 = [];
                const newData4 = [];
                const newData5 = [];
                const newData6 = [];
                const newData7 = [];
                const newData8 = [];
    
                allData.forEach(item => {
                    switch (item.documentCategoryIDX) {
                        case 60:
                            newData1.push(item);
                            break;
                        case 61:
                            newData2.push(item);
                            break;
                        case 62:
                            newData3.push(item);
                            break;
                        case 63:
                            newData4.push(item);
                            break;
                        case 64:
                            newData5.push(item);
                            break;
                        case 65:
                            newData6.push(item);
                            break;
                        case 66:
                            newData7.push(item);
                            break;
                        case 67:
                            newData8.push(item);
                            break;
                        default:
                            console.warn(`Unexpected category: ${item.documentCategoryIDX}`);
                    }
                });
    
                setData1(newData1);
                setData2(newData2);
                setData3(newData3);
                setData4(newData4);
                setData5(newData5);
                setData6(newData6);
                setData7(newData7);
                setData8(newData8);

            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const [returnTableList, setReturnTableList] = useState(null);

    const columns = [
        { title: '분류', dataIndex: 'documentCategoryName', className: 'p_td_w_20' },
        { title: '제목', dataIndex: 'title', className: 'p_td_title p_td_w_60' },
        { title: '날짜', dataIndex: 'createTime', className: 'p_td_date' },
    ];

    const pageSize = 10;

    const categoryOptions = [
        { label: '전체', value: '전체' },
        { label: '제목', value: 'title' },
        { label: '내용', value: 'contents' },
    ];

    useEffect(() => {
        let selectedData = [];
    
        switch (pageid) {
            case '1':
                selectedData = data1;
                break;
            case '2':
                selectedData = data2;
                break;
            case '3':
                selectedData = data3;
                break;
            case '4':
                selectedData = data4;
                break;
            case '5':
                selectedData = data5;
                break;
            case '6':
                selectedData = data6;
                break;
            case '7':
                selectedData = data7;
                break;
            case '8':
                selectedData = data8;
                break;
            default:
                selectedData = data1;
        }
    
        setSelectedData(selectedData);
        setReturnTableList(
            <TableList
                key={pageid}
                data={selectedData}
                columns={columns}
                pageSize={pageSize}
                categoryOptions={categoryOptions}
                type="Editor"
            />
        );
    
    }, [pageid, data1, data2, data3, data4, data5, data6, data7, data8]);

    // =================================

    

    const overviewRef = useRef(null);
    const forumHistoryRef = useRef(null);

    const handleButtonClick = (section) => {
        if (section === 'overview' && overviewRef.current) {
            window.scrollTo({
                top: overviewRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        } else if (section === 'forumHistory' && forumHistoryRef.current) {
            window.scrollTo({
                top: forumHistoryRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        }
    };

    


    return (
        <>
            <div className="components_wrap">
                <div className={styles.tab_btn_box} ref={overviewRef}>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        개요
                    </button>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        분과 행사
                    </button>
                </div>



                {/* ==================== 목적 ==================== */}

                <div className={styles.cont_wrap}>
                    {/* <div className={styles.cont_tit_box}>
                        <span>목적</span>
                    </div> */}
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list}>
                        {/* {pageid === '1' &&
                            <>
                                <li>정책입안자, 산업계 최고경영인 및 학계 석학들의 집단지성으로 올바른 정책을 개발하고 건의함으로써 기업과 국가의 경쟁력 제고</li>
                                <li>정책입안자, 최고경영인 및 학계 석학들의 의견 교환을 통한 올바른 정책 개발 및 건의</li>
                                <li>국내외 최신 산업기술, 공학교육, R&D 전략 등에 대한 정보의 공유</li>
                            </>
                        }
                        {pageid === '2' &&
                            <>
                                <li>국가적인 주요 현안에 대한 여론 형성 및 국론 결집</li>
                                <li>참여 인사들 간 국내외 산업기술, 공학교육, R&D 전략 등에 대한 최신 정보의 공유</li>
                            </>
                        }
                        {pageid === '3' &&
                            <>
                                <li>한국공학한림원 CEO/CTO회원 상호간에 전문 경영정보를 공유하고 경영 노하우를 벤치마킹 함으로써 기업의 경쟁력 강화</li>
                                <li>지식 경영의 궁극적 목적인 기업과 국가의 경쟁력 제고에 기여</li>
                            </>
                        }
                        {pageid === '4' &&
                            <>
                                <li>국토발전 전략을 모색하고 나아가 미래지향적 비전과 정책 방향 제시</li>
                            </>
                        }
                        {pageid === '5' &&
                            <>
                                <li>지식재산 정책·이슈에 대한 오피니언 리더들의 심도 있는 토론을 통해 특허 친화적인 국가발전 전략 및 정책 제시</li>
                                <li>지식재산 정책에 대한 사회 전반의 인식제고 및 대한민국 지식 재산 경쟁력 강화</li>
                            </>
                        } */}
                        <div className={styles.depart_img_box}>
                            <img src="/img/member_depart.png" alt="" />
                        </div>
                        </ul>
                    </div>
                </div>













                


                <div className={`${styles.tab_btn_box} ${styles.m_t_b_100}`} ref={forumHistoryRef}>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        개요
                    </button>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        분과 행사
                    </button>
                </div>
                {returnTableList}
                {/* <TableList data={data} columns={columns} pageSize={pageSize} categoryOptions={categoryOptions} type="Editor" /> */}
            </div>
        </>
    );
}

export default MemberDepart;