import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { BrowserRouter as Router, Routes, Route, useLocation, Outlet } from 'react-router-dom';
import { Login, LoginModal, Main, MyPage, MyCertificate, FindIdPw, Greeting, Purpose, Chairman, Dept, Committee ,Contact, ChairmanContact , Ci, Direction, Disclosure, RegularDebate, PolicySeries, PromotionEngineering, InternationalExchange, Competition, MembersIntro, Status, MembersSearch, MembersProcess, Nomi, Progress, MemberDepart, Calendar, ProjectParti, ProjectRegist,Donation, Suggest, Notice,Recruitment, Bidding, Report, PressRelease, MembersNews, Clean,CandidateList,IntegrationSearch, PasswordUri,Payment,PaymentHistory,PaymentResult,PaymentSuccess,PaymentRefund, Privacy

 } from "./pages";
import { Detail, UserDetail, EventDetail, ProjectDetail, EngDetail } from "./components";
import Header from './Header';
import Breadcrumb from './Breadcrumb';
import Block from './Block';
import Footer from './Footer';
import EngFooter from './EngFooter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './ProtectedRoute';
import { isLoginSelector, TokenAtom } from './Recoil/TokenAtom';
import { useRecoilValue, useSetRecoilState } from 'recoil';




import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';





import {

    EngMain,

    EngGreeting,
    EngHistory,
    EngDept,
    EngMembership,
    EngContact,



    EngCaets,
    EngPolicy,
    EngForum,
    EngAwards,
    EngEngineer,


    EngReport,

    EngRelatedOrganization,



} from "./pages";


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const menuSet = [
    {
        idTxt: 'intro', name: '기관소개', link: '/greeting', subMenu: [
            { id: '1', name: '인사말/비전', link: '/greeting' },
            { id: '2', name: '설립목적/연혁', link: '/purpose' },
            { id: '3', name: '역대 이사장&회장', link: '/chairman' },
            { id: '4', name: '조직도', link: '/dept' },
            { id: '4', name: '위원회 현황', link: '/committee', gnbView: false},
            { id: '4', name: '연락처', link: '/contact', gnbView: false},
            { id: '4', name: '회장실', link: '/chairmanContact', gnbView: false},
            { id: '4', name: '집행위원회', link: '/execution', gnbView: false },
            { id: '5', name: 'CI 및 심볼', link: '/ci' },
            { id: '6', name: '오시는길', link: '/direction' },
            { id: '7', name: '경영공시', link: '/disclosure' },
        ]
    },
    {
        idTxt: 'business', name: '사업안내', link: '/regulardebate?pageid=1', subMenu: [
			{ id: '1', name: '정기토론회', link: '/regulardebate?pageid=1', subMenu: [
				{ 
					id: '1-1', name: 'NAEK 포럼', link: '/regulardebate?pageid=1' ,
					midTitSub:'1998년부터 시작된 한국공학한림원의 대표적인 정책토론회입니다.<br />산업계 최고경영인, 학계 석학 및 국가 정책입안자의 토론을 통하여 기업 경쟁력과 국가 공학기술 발전을 위한 전략을 제시합니다.',
					midTitboxImg:'t2-1.jpg'
                },
				{ 
					id: '1-2', name: '코리아리더스포럼', link: '/regulardebate?pageid=2' ,
					midTitSub:'2003년부터 시작된 코리아리더스포럼은 정치, 경제, 사회 분야 국가적 이슈나 글로벌 아젠다를 발굴하고<br />Opinion Leader 중심으로 혁신 국가의 지향점을 제시하고 있습니다.',
					midTitboxImg:'t2-2.jpg'
                },
				{ 
					id: '1-3', name: 'CEO조찬집담회', link: '/regulardebate?pageid=3' ,
					midTitSub:'CEO조찬집담회에서 한국공학한림원 산업계 CEO/CTO회원들이 최신 산업 및 기술에 대한 정보와 경영 노하우를 공유합니다.',
					midTitboxImg:'t2-3.jpg'
                },
				{ 
					id: '1-4', name: '미래국토포럼', link: '/regulardebate?pageid=4' ,
					midTitSub:'한국공학한림원은 국토 균형발전 비전과 정책 방향을 제시하기 위해 2014년에 한반도국토포럼을 출범하였습니다.<br />그 후, 2023년 부터 ‘미래국토포럼 (Future Land Forum)’으로 명칭을 변경하여 한반도의 국토발전을 넘어 미래 사회의 도약을 위한 방향성을 모색합니다.',
					midTitboxImg:'t2-4.jpg'
                },
				// { 
				// 	id: '1-5', name: 'IP전략포럼', link: '/regulardebate?pageid=5' ,
				// 	midTitSub:'IP전략포럼은 “Pax Technica” 시대에 대한민국 기술주권 확립을 위하여 지식재산 우호적 생태계를 조성하고, 산업발전을 위한 지식재산 전략을 제시합니다.',
				// 	midTitboxImg:'t2-5.jpg'
                // },
			]},
			{ id: '2', name: '정책연구', link: '/policyseries?pageid=1', subMenu: [
				{ 
					id: '2-1', name: '정책총서', link: '/policyseries?pageid=1' ,
					midTitSub:'우리나라 산업과 기술발전을 위한 비전과 과제들을 담은 보고서로서 한국공학한림원 전체 회원의 식견을 종합한 결과물입니다.<br />대통령 선거 전에 발간하여 배포하고, 각 당 정책위원회 의장을 초청하여 정책토론회를 개최해 왔습니다.',
					midTitboxImg:'t2-6.jpg'
                },
				{ 
					id: '2-2', name: '중장기 정책연구', link: '/policyseries?pageid=2' ,
					midTitSub:'국가적으로 중요한 이슈에 대한 중장기적인 연구를 통해 실질적인 정책을 제안하는 준상설화된 조직인 특별위원회를 운영합니다.<br />현재 정책위원회, 인재양성위원회, 산업미래전략위원회, 지식재산전략연구회, 자율주행위원회가 활동하고 있습니다.',
					midTitboxImg:'t2-7.jpg'
                },
				{ 
					id: '2-3', name: '단기 정책연구', link: '/policyseries?pageid=3' ,
					midTitSub:'당면한 사회 이슈에 즉각적으로 대응하여 해결방안을 도출하는 단기정책연구 과제를 지원하고 있습니다.<br />공모를 통해 연구과제를 선정하고 공학한림원 회원이 중심이 되어 연구 진행하고 결과보고서를 발표합니다.',
					midTitboxImg:'t2-8.jpg'
                },
				{ 
					id: '2-4', name: 'NAEK Voice', link: '/policyseries?pageid=4' ,
					midTitSub:'공학, 기술과 관련된 주요 현안에 대한 객관적인 분석과 책임 있는 목소리를 통해 사회가 나아가야 할 방향을 제시합니다.<br />공학한림원 회원 및 관련 전문가의 의견을 수렴하여 NAEK VOICE를 정기적으로 발간하고 정부부처, 국회, 학회, 언론 등에 배포하고 있습니다.',
					midTitboxImg:'t2-9.jpg'
                },
				{ 
					id: '2-5', name: '산업기술 성과', link: '/policyseries?pageid=5' ,
					midTitSub:'국민들에게 산업기술의 중요성을 알리고 연구자들 간 최신기술과 산업정보를 공유할 수 있도록 한국의 산업기술 성과를 매년 발굴·홍보하고 있습니다.<br />Engineering Academy로서 한 해 동안 탁월한 기술력으로 높은 성과를 이룬 기술에 대한 보고서를 지속적으로 발간하고자 합니다.',
					midTitboxImg:'t2-10.jpg'
                },
			]},
			{ id: '3', name: '공학기술진흥', link: '/promotionEngineering?pageid=1', subMenu: [
				{ 
					id: '3-1', name: '공학기술교양도서', link: '/promotionEngineering?pageid=1' ,
					midTitSub:'한국공학한림원은 대중의 공학기술에 대한 거리감을 좁히고 다양한 정보와 지식을 전달하는 수단으로서 매년 공학기술교양도서를 발간하고 있습니다.',
					midTitboxImg:'t2-11.jpg'
                },
				{ 
					id: '3-2', name: '주니어공학기술교실', link: '/promotionEngineering?pageid=2' ,
					midTitSub:'한국공학한림원은 대중의 공학기술에 대한 거리감을 좁히고 다양한 정보와 지식을 전달하는 수단으로서 매년 공학기술교양도서를 발간하고 있습니다.',
					midTitboxImg:'t2-12.jpg'
                },
				{ 
					id: '3-3', name: '차세대공학리더', link: '/promotionEngineering?pageid=3' ,
					midTitSub:'다양한 전공의 공학도들로 구성된 단체로, 공학도들 사이의 교류를 활성화하고 전국적인 규모의 공대생 네트워크입니다.<br />끝없는 잠재력을 지닌 공학도들이 앞으로 우리나라를 이끄는 리더로 성장할 수 있도록 다양한 프로그램을 지원하고 있습니다.',
					midTitboxImg:'t2-13.jpg'
                },
				{ 
					id: '3-4', name: '차세대지식재산리더', link: '/promotionEngineering?pageid=4' ,
					midTitSub:'차세대지식재산리더는 젊은 창의력과 전략적 사고를 통해 새로운 가치를 창조하고, 국가와 국제 사회의 발전에 기여할 수 있도록 2011년 5월 31일 설립하여 활발한 활동을 이어가고 있습니다.',
					midTitboxImg:'t2-14.jpg'
                },
				{ 
					id: '3-5', name: '석학멘토링', link: '/promotionEngineering?pageid=5' ,
					midTitSub:'한국공학한림원은 회원들의 지식과 노하우가 다음 세대로 유익하게 이어질 수 있도록 석학 멘토링 사업을 운영하고 있습니다.<br />대학생 멘티들에게 석학 멘토들의 직업현장과 기술개발 등의 경험과 역량, 비전을 공유하는 소중한 기회를 제공하고 있습니다.',
					midTitboxImg:'t2-15.jpg'
                },
			]},
			{ id: '4', name: '국제교류 및 협력', link: '/internationalexchange?pageid=1', subMenu: [
				{ 
					id: '4-1', name: '세계공학한림원평의회', link: '/internationalexchange?pageid=1' ,
					midTitSub:'1국가 1회원을 원칙으로 하는 공학 분야의 최고 평의회로, 한국공학한림원은 2000년 가입이래 세계공학한림원과의 네트워크를 구축하고 공학한림원의 정체성을 제고하기 위해 매년 활발히 참여하고 있습니다.',
					midTitboxImg:'t2-16.jpg'
                },
				{ 
					id: '4-2', name: '한중일 공학한림원 공동협력', link: '/internationalexchange?pageid=2' ,
					midTitSub:'동북아 지역 내 지속적인 지식 네트워크를 운영하여 미래 산업 기술 경쟁력 제고에 기여를 목표로<br />한중일 유망기술 발굴 및 공동 대응책 마련을 위해 협력 사업을 추진해오고 있습니다.',
					midTitboxImg:'t2-17.jpg'
                },
				{ 
					id: '4-3', name: '한-호주 이노베이션 워크숍', link: '/internationalexchange?pageid=3' ,
					midTitSub:'에너지 강국인 호주와 첨단 산업 제조국인 한국의 공통 관심사이자 다양한 기술 교류가 가능한<br />에너지 분야, 특히 수소 산업을 중심으로 기술 현황 및 주요 이슈를 검토하고, 향후 정책 및 전략 수립에 반영하고 있습니다.',
					midTitboxImg:'t2-18.jpg'
                },
				{ 
					id: '4-4', name: '한-아세안 엔지니어링 포럼', link: '/internationalexchange?pageid=4' ,
					midTitSub:'한국공학한림원은 아세안공학한림원(AAET)와의 파트너십을 통해 글로벌 아젠다에 대한 정책기술교류를 추진하고 있습니다.<br />또 한-아세안 전문가 그룹 활동을 통해 보다 실질적인 협력성과 도출을 도모하고 있습니다.',
					midTitboxImg:'t2-19.jpg'
                },
				{ 
					id: '4-5', name: '한-중국 협력사업', link: '/internationalexchange?pageid=5' ,
					midTitSub:'한국공학한림원은 2014년부터 중국공정원(CAE)과 다양한 형태의 산업기술교류를 추진해오고 있습니다.<br />2023년부터는 \'한-중 공학기술발전포럼\'을 통해 주요 기술 분야에 대한 교류협력을 강화하고 있습니다.',
					midTitboxImg:'t2-20.jpg'
                },
				{ 
					id: '4-6', name: '한-영국 협력사업', link: '/internationalexchange?pageid=6' ,
					midTitSub:'한국공학한림원은 2015년부터 영국공학한림원(RAEng)과 다양한 형태의 협력사업을 시도해왔습니다.<br />2023년부터는 청정 에너지에 대한 공통된 관심을 바탕으로 탄소중립을 위한 정책기술포럼을 매년 운영하고 있습니다.',
					midTitboxImg:'t2-21.jpg'
                },
				{ 
					id: '4-7', name: '한-인도 협력사업', link: '/internationalexchange?pageid=7' ,
					midTitSub:'양국의 관심사이자 다양한 기술 교류가 가능한 첨단재료, 항공우주 산업 등을 중심으로<br />기술 현황 및 주요 이슈를 검토하여 한-인도 연구자 간 추가 협력의 계기를 마련하고 있습니다.',
					midTitboxImg:'t2-22.jpg'
                },
			]},
			{ id: '5', name: '시상·대회', link: '/awards?pageid=1', subMenu: [
				{ 
					id: '5-1', name: '대상·젊은공학인상', link: '/awards?pageid=1' ,
					midTitSub:'1996년에 제정된 한국공학한림원의 대표적인 시상사업입니다.<br />대한민국 산업발전에 크게 기여한 공학기술인을 발굴·우대함으로써 기술 문화의 확산과 국가경제발전 기반 구축에 기여하고 있습니다.',
					midTitboxImg:'t2-23.jpg'
                },
				{ 
					id: '5-2', name: '일진상', link: '/awards?pageid=2' ,
					midTitSub:'국가 기술발전의 토대를 공고히 하고자 2004년 제정된 시상사업입니다.<br />기술정책 개발 및 산학협력 증진을 통해 공학기술 진흥에 공헌한 인물을 발굴하여 그 공을 기리고자 합니다.',
					midTitboxImg:'t2-24.jpg'
                },
				{ 
					id: '5-3', name: '해동상', link: '/awards?pageid=3' ,
					midTitSub:'국가 기술발전의 토대를 공고히 하고자 2005년 제정된 시상사업입니다.<br />우리나라 공학기술 발전을 위해 공학기술 문화확산 및 공학교육 혁신 등에 공헌한 인물을 발굴하여 시상합니다.',
					midTitboxImg:'t2-25.jpg'
                },
				{ 
					id: '5-4', name: '원익 차세대 공학도상', link: '/awards?pageid=4' ,
					midTitSub:'공학기술 인재의 새로운 상을 정립하고, &nbsp;사회문제 해결에 적극적인 엔지니어로 성장할 수 있도록 격려하고 지원하기 위해 창의적이고 도전적인 공학도를 발굴하여 시상합니다.',
					midTitboxImg:'t2-26.jpg'
                },
				{ 
					id: '5-5', name: '캠퍼스특허유니버시아드', link: '/awards?pageid=5' ,
					midTitSub:'대학의 특허 데이터 활용·분석교육 확대를 통해 기업이 필요로 하는 지식재산 인재를 양성하고,<br />대학의 창의적 아이디어를 산업계에 공급하기 위하여 특허청과 함께 주최하고 있습니다.',
					midTitboxImg:'t2-27.jpg'
                },
			]},
            
            // { id: '6', name: '게시글', link: '/noticedetail', gnbView: false },
		]
    },
    // {
    //     idTxt: 'members', name: '회원', link: '/membersintro', subMenu: [
    //         { id: '1', name: 'NAEK 회원 소개', link: '/membersintro' },
    //         { id: '2', name: '회원 현황', link: '/status' },
    //         { id: '3', name: '회원검색', link: '/memberssearch' },
    //         { id: '4', name: '회원 선출 절차', link: '/membersprocess' },
    //         { id: '5', name: '회원심사', link: '/progress', subMenu: [
    //             { id: '5-1', name: '후보자 추천', link: '/candidatelist' },
    //             { id: '5-2', name: '신입회원 지명추천서 등록', link: '/nomi' },
    //             { id: '5-3', name: '심사 진행상황', link: '/progress' },
    //         ]},
    //         { id: '6', name: '회원정보', link: '/userdetail', gnbView: false},
    //         { id: '7', name: '전문분과위원회', link: '/membersintro' },//개선사항엑셀
    //     ]
    // },
    {
        idTxt: 'members', name: '회원', link: '/membersintro', subMenu: [
            { id: '1', name: '회원 소개 및 현황', link: '/membersintro' },
            // { id: '2', name: '회원 현황', link: '/status' },
            { id: '3', name: '회원검색', link: '/memberssearch' },
            { id: '4', name: '회원정보', link: '/userdetail', gnbView: false},
            { id: '5', name: '회원심사', link: '/progress', subMenu: [
                { id: '5-1', name: '후보자 추천', link: '/candidatelist' },
                { id: '5-2', name: '신입회원 지명추천서 등록', link: '/nomi' },
                { id: '5-3', name: '회원 선출 절차', link: '/membersprocess' },
            ]},
            { id: '6', name: '회비결제', link: '/payment' },
            { id: '61', name: '회비 결제내역', link: '/paymenthistory', gnbView: false},
            { id: '62', name: 'result', link: '/paymentresult', gnbView: false},
            { id: '63', name: 'success', link: '/paymentsuccess', gnbView: false},
            { id: '64', name: 'refund', link: '/paymentrefund', gnbView: false},
            { id: '7', name: '전문분과위원회', link: '/memberDepart?pageid=1',subMenu: [
				{ 
					id: '7-1', name: '전기전자공학분과', link: '/memberDepart?pageid=1' ,
					midTitSub:'전기에너지의 생성과 응용, 반도체 설계 및 제조, 유무선통신 및 신호처리, 디스플레이 등을 포함하는 전기전자 기반 분야와 더불어 타 분야와의 융합 신기술도 함께 다루는 전문 분과',
					midTitboxImg:'t2-10.jpg',
                    // gnbView: false
                },
				{ 
					id: '7-2', name: '기계공학분과', link: '/memberDepart?pageid=2' ,
					midTitSub:'기계 설비, 장비 등 전통 분야와 자동차, 철도, 조선, 우주항공 등 핵심 교통수단 산업에 로보틱스, 메카트로닉스 등 타 분야와의 기술융합을 다루는 전문 분과',
					midTitboxImg:'t2-10.jpg',
                    // gnbView: false
                },
				{ 
					id: '7-3', name: '건설환경공학분과', link: '/memberDepart?pageid=3' ,
					midTitSub:'건축과 도시, 인프라 시스템, 지속가능한 자연 환경에 관련한 건축, 도시, 환경, 지반, 구조, 교통, 건설, 수자원 공학 분야와 더불어 융합 신기술의 적용 연구 및 개발을 포함하는 전문 분과',
					midTitboxImg:'t2-10.jpg',
                    // gnbView: false
                },
				{ 
					id: '7-4', name: '화학생물공학분과', link: '/memberDepart?pageid=4' ,
					midTitSub:'화학 및 생물학적 시스템에 있어 성능, 지속가능성과, 사회적 가치가 향상된 재료, 공정 및 제품을 특성화하고 설계하는 전문 분과',
					midTitboxImg:'t2-10.jpg',
                    // gnbView: false
                },
				{ 
					id: '7-5', name: '재료자원공학분과', link: '/memberDepart?pageid=5' ,
					midTitSub:'물질의 성분과 구조에 따른 물성 변화에 대한 이해를 바탕으로 공정 최적화를 통해 산업사회의 지속가능성 확보에 필요한 재료 및 에너지자원을 연구, 개발, 실용화 하기 위해 활동하는 전문 분과',
					midTitboxImg:'t2-10.jpg',
                    // gnbView: false
                },
                { 
					id: '7-6', name: '기술경영정책분과', link: '/memberDepart?pageid=6' ,
					midTitSub:'기술개발과 융합, 사업화 촉진을 통한 경제사회 문제 해결과 산업발전을 위한 기업가정신, 혁신경영, 국가정책 및 제도 등의 이슈를 다루는 전문 분과',
					midTitboxImg:'t2-10.jpg',
                    // gnbView: false
                },
                { 
					id: '7-7', name: '컴퓨팅분과', link: '/memberDepart?pageid=7' ,
					midTitSub:'컴퓨터, 컴퓨팅, 정보 및 그 연결에 필요한 이론과 기술을 다루며, 타 분야에서의 응용과 융합은 물론, 더 나아가 디지털 시대에 맞는 교육, 윤리, 인권 등 사회적 영향도 포함하는 전문 분과',
					midTitboxImg:'t2-10.jpg',
                    // gnbView: false
                },
                { 
					id: '7-8', name: '바이오메디컬분과', link: '/memberDepart?pageid=8' ,
					midTitSub:'생명공학, 의·약학 지식에 기초하여 인체의 진단과 치료를 목적으로 하는 의약품, 의료기기, 첨단재생 의료 및 헬스케어 전반의 연구 및 개발을 포함하는 전문 분과',
					midTitboxImg:'t2-10.jpg',
                    // gnbView: false
                },
            ]
            },
        ]
    },
    {
        idTxt: 'participation', name: '참여', link: '/calendar?pageid=1', subMenu: [
            { id: '1', name: '행사 캘린더', link: '/calendar?pageid=1' },
            { id: '21', name: '행사 참여', link: '/calendar?pageid=2', gnbView: false },
            { id: '22', name: '행사 참석신청', link: '/eventdetail', gnbView: false },
            { id: '31', name: '사업참여', link: '/projectparti' },
            { id: '32', name: '사업 참여신청', link: '/projectdetail', gnbView: false },
            { id: '33', name: '사업 참여신청', link: '/projectregist', gnbView: false },
            { id: '4', name: '기부안내', link: '/donation',
                midTitSub:'여러분께서 출연해 주신 기금은 공학기술 및 산업 발전을 위한 정책연구, 우수 공학인 포상, 공학문화 확산 (주니어공학기술교실, 도서출판 배포 등) 사업에 소중하게 사용될 것입니다.',
                midTitboxImg:'t4-1.jpg'
             },
            // { id: '5', name: '공학한림원에 바란다', link: '/suggest' },
        ]
    },
    {
        idTxt: 'news', name: '소식', link: '/notice', subMenu: [
            { id: '1', name: '공지사항', link: '/notice' },
            { id: '1', name: '채용공고', link: '/recruitment', gnbView: false },
            { id: '1', name: '입찰공고', link: '/bidding', gnbView: false },
            { id: '2', name: '정기간행물/보고서', link: '/report' },
            { id: '3', name: '보도자료', link: '/pressrelease' },
            { id: '4', name: '회원동정', link: '/membersnews' },
            { id: '5', name: '반부패 청렴활동', link: '/clean' }
            // { id: '5', name: '통합검색', link: '/integrationsearch', gnbView: false }
        ]
    },
    {
        idTxt: 'mypage', name: '내정보', link: '/mypage', gnbView: false
    },
    {
        idTxt: 'mycertificate', name: '회원확인증', link: '/mycertificate', gnbView: false
    },
    {
        idTxt: 'privacy', name: '개인정보취급방침', link: '/privacy', gnbView: false
    },
];
























const engMenuSet = [
    {
        idTxt: 'NAEK', name: 'NAEK', link: '/en/greeting', subMenu: [
            { id: '1', name: "President's Greetings", link: '/en/greeting' },
            { id: '2', name: 'History', link: '/en/history' },
            { id: '3', name: 'Organization', link: '/en/dept' },
            { id: '4', name: 'Membership', link: '/en/status' },
            { id: '5', name: 'Contact', link: '/en/contact' },
        ]
    },
    {
        idTxt: 'Project', name: 'Project', link: '/en/caets?pageid=1', subMenu: [
			{ id: '1', name: 'International Activities', link: '/en/caets?pageid=1', subMenu: [
				{ 
					id: '1-1', name: 'CAETS', link: '/en/caets?pageid=1' ,
					midTitSub:'CAETS(International Council of Academies of Engineering and Technological Sciences) is the highest international authority in engineering, with one member academy per country. Since joining CAETS in 2000, NAEK has been actively engaged in networking with other academies from around the world and strengthening its identity as a national academy.',
					midTitboxImg:'eng_t2-1.JPG'
                },
				{ 
					id: '1-2', name: 'East Asia Round Table Meeting', link: '/en/caets?pageid=2' ,
					midTitSub:'In collaboration with China and Japan, we are developing a sustainable knowledge network in East Asia to enhance the national competitiveness in future industrial technology, discover promising technologies, and prepare joint countermeasures among the three countries.',
					midTitboxImg:'eng_t2-2.jpg'
                },
                { 
					id: '1-3', name: 'ATSE-NAEK Workshop', link: '/en/caets?pageid=3' ,
					midTitSub:'Common interests of Australia, an energy powerhouse, and Korea, a high-tech industrial manufacturer, enabling diverse technology exchanges <br/>We review technology status and major issues, especially in the hydrogen industry, and reflect them in future policies and strategies.',
					midTitboxImg:'t2-18.jpg'
                },
				{ 
					id: '1-4', name: 'ASEAN-Korea Engineering Forum', link: '/en/caets?pageid=4' ,
					midTitSub:'NAEK is actively fostering policy and technology exchanges on global agendas in partnership with the ASEAN Academy of Engineering and Technology(AAET). Concurrently, we are dedicated to facilitating tangible collaborations between ASEAN and Korea through targeted professional group activities.',
					midTitboxImg:'eng_t2-3.png'
                },
				{ 
					id: '1-5', name: 'China-Korea Forum', link: '/en/caets?pageid=5' ,
					midTitSub:'NAEK has been promoting diverse forms of industrial technology exchanges with the Chinese Academy of Engineering(CAE) since 2014. Commencing in 2023, both academies are intensifying exchanges and cooperation in pivotal technology sectors through the "China-Korea Development Forum on Engineering Sciences and Technology".',
					midTitboxImg:'eng_t2-4.jpg'
                },
				{ 
					id: '1-6', name: 'Korea-UK Policy and Technology Forum', link: '/en/caets?pageid=6' ,
					midTitSub:'Since 2015, NAEK has been engaged in a variety of partnership activities with the Royal Academy of Engineering(RAEng) in the United Kingdom. Building on shared interests in clean energy, both academies are organizing an annual Policy and Technology Forum on Net Zero starting in 2023.',
					midTitboxImg:'eng_t2-5.jpg'
                },
                { 
					id: '1-7', name: 'INAE-NAEK Workshop', link: '/en/caets?pageid=7' ,
					midTitSub:'We review the current state of technology and key issues in areas of mutual concern, such as advanced materials and aerospace industry, to foster further collaboration between Korean and Indian researchers.',
					midTitboxImg:'eng_t2-6.jpg'
                },
			]},
			{ id: '2', name: 'Policy Proposal', link: '/en/policy', subMenu: []},
			{ id: '3', name: 'Forum & Event', link: '/en/forum', subMenu: []},
			{ id: '4', name: 'Awards', link: '/en/awards', subMenu: []},
			{ id: '5', name: 'Engineering in Community', link: '/en/engineer', subMenu: []},
		]
    },
    {
        idTxt: 'participation', name: 'Publication', link: '/en/report', subMenu: [
            { id: '1', name: 'Activity Report', link: '/en/report'},
        ]
    },
    {
        idTxt: 'news', name: 'Related Organizations', link: '/en/relatedOrganization', subMenu: [
            { id: '1', name: 'Related Organizations', link: '/en/relatedOrganization' },
        ]
    },
];





























function Layout({ openLoginModal }) {
    return (
        <>
            <Header openLoginModal={openLoginModal} menuSet={menuSet} />
            <Breadcrumb menuSet={menuSet} />
            <Outlet />
            <Footer />
        </>
    );
}




function EngLayout({ openLoginModal }) {
    return (
        <>
            <Header menuSet={engMenuSet} />
            <Breadcrumb menuSet={engMenuSet} />
            <Outlet />
            <EngFooter />
        </>
    );
}

function AppContent() {
    const isLogin = useRecoilValue(isLoginSelector);
    if(isLogin){
        const checkLogin = async () => {
            try {
                const response = await axios.post(`${API_BASE_URL}/naek_login/in/page_login_chk`, {}, {
                    withCredentials: true
                });
                if (response.data.result === 't') {
                } else {
                    // 브라우저는 로그인으로 알고 있지만 사실 로그아웃이다..!
                    setAccessToken(undefined);
                    localStorage.removeItem('accessToken'); // 
                    // isLogin=false;
                }
            } catch (error) {
                console.error('Auth check failed:', error);
            } finally {
            }
        };
        checkLogin();
    }

    const setAccessToken = useSetRecoilState(TokenAtom);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);

    const openLoginModal = () => {
        setLoginModalOpen(true);
    };

    const closeLoginModal = () => {
        setLoginModalOpen(false);
    };

    const location = useLocation();

    useEffect(() => {
        // const token = sessionStorage.getItem('accessToken');
        const token = localStorage.getItem('accessToken');
        
        if (token) {
            setAccessToken(token); // 저장된 토큰을 Recoil 상태로 설정
        }
    }, [setAccessToken]);

    // useEffect(() => {
    //     if (isLoginModalOpen) {
    //         closeLoginModal();
    //     }
    // }, [location]);
    useEffect(() => {
        if (isLoginModalOpen && isLogin) {
            closeLoginModal();
        }
    }, [isLogin, isLoginModalOpen]);

    return (
        <div className="App">
            <Routes>
                <Route path="/*" element={<Block />} />
                <Route element={<Layout openLoginModal={openLoginModal} />}>
                    <Route path="/" element={<Main />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/noticedetail" element={<Detail />} />
                    <Route path="/userdetail" element={<UserDetail isLogin={isLogin} />} />
                    <Route path="/eventdetail" element={<EventDetail isLogin={isLogin} />} />
                    <Route path="/projectdetail" element={<ProjectDetail />} />
                    <Route path="/findIdPw" element={<FindIdPw />} />
                    <Route path="/passwordUri" element={<PasswordUri />} />
                    <Route path="/privacy" element={<Privacy />} />

                    {/* NAEK 소개 */}
                    <Route path="/greeting" element={<Greeting />} />
                    <Route path="/purpose" element={<Purpose />} />
                    <Route path="/chairman" element={<Chairman />} />
                    <Route path="/dept" element={<Dept />} />
                    <Route path="/committee" element={<Committee />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/chairmanContact" element={<ChairmanContact />} />
                    <Route path="/ci" element={<Ci />} />
                    <Route path="/direction" element={<Direction />} />
                    <Route path="/disclosure" element={<Disclosure />} />

                    {/* 사업안내 */}
                    <Route path="/regulardebate" element={<RegularDebate />} />
                    <Route path="/policyseries" element={<PolicySeries />} />
                    <Route path="/promotionEngineering" element={<PromotionEngineering />} />
                    <Route path="/internationalexchange" element={<InternationalExchange />} />
                    <Route path="/awards" element={<Competition />} />

                    {/* 회원 */}
                    <Route path="/membersintro" element={<MembersIntro />} />
                    <Route path="/status" element={<Status />} />
                    <Route path="/memberssearch" element={<MembersSearch isLogin={isLogin} />} />
                    <Route path="/membersprocess" element={<MembersProcess />} />
                    <Route path="/candidatelist" element={<CandidateList isLogin={isLogin} />} />
                    <Route path="/nomi" element={<Nomi isLogin={isLogin} />} />
                    <Route path="/progress" element={<Progress />} />
                    <Route path="/memberDepart" element={<MemberDepart />} />
                    
                    <Route path="/payment" element={<Payment isLogin={isLogin} />} />
                    <Route path="/paymenthistory" element={<PaymentHistory isLogin={isLogin} />} />
                    <Route path="/paymentresult" element={<PaymentResult isLogin={isLogin} />} />
                    <Route path="/paymentsuccess" element={<PaymentSuccess isLogin={isLogin} />} />
                    <Route path="/paymentrefund" element={<PaymentRefund isLogin={isLogin} />} />

                    {/* NAEK 참여 */}
                    <Route path="/calendar" element={<Calendar isLogin={isLogin} />} />
                    <Route path="/projectparti" element={<ProjectParti />} />
                    <Route path="/projectregist" element={<ProjectRegist />} />
                    <Route path="/donation" element={<Donation />} />
                    {/* <Route path="/suggest" element={<Suggest />} /> */}

                    {/* 소식 */}
                    <Route path="/notice" element={<Notice />} />
                    <Route path="/recruitment" element={<Recruitment />} />
                    <Route path="/bidding" element={<Bidding />} />
                    <Route path="/report" element={<Report />} />
                    <Route path="/pressrelease" element={<PressRelease />} />
                    <Route path="/membersnews" element={<MembersNews />} />
                    <Route path="/clean" element={<Clean />} />
                    <Route path="/integrationsearch" element={<IntegrationSearch />} />
                    
                    {/* 회원전용 */}
                    {/* <Route element={<ProtectedRoute />}> */}
                        <Route path="/mypage" element={<MyPage />} />
                        <Route path="/mycertificate" element={<MyCertificate />} />
                    {/* </Route> */}
                </Route>





                <Route element={<EngLayout />}>
                    <Route path="/en" element={<EngMain />} />
                    <Route path="/engdetail" element={<EngDetail />} />
                    {/* <Route path="/noticedetail" element={<Detail />} />
                    <Route path="/userdetail" element={<UserDetail />} />
                    <Route path="/eventdetail" element={<EventDetail />} /> */}

                    {/* NAEK 소개 */}
                    <Route path="/en/greeting" element={<EngGreeting />} />
                    <Route path="/en/history" element={<EngHistory />} />
                    <Route path="/en/dept" element={<EngDept />} />
                    <Route path="/en/status" element={<EngMembership />} />
                    <Route path="/en/contact" element={<EngContact />} />


                    <Route path="/en/caets" element={<EngCaets />} />
                    <Route path="/en/policy" element={<EngPolicy />} />
                    <Route path="/en/forum" element={<EngForum />} />
                    <Route path="/en/awards" element={<EngAwards />} />
                    <Route path="/en/engineer" element={<EngEngineer />} />

                    <Route path="/en/report" element={<EngReport />} />

                    <Route path="/en/relatedOrganization" element={<EngRelatedOrganization />} />

                </Route>
            </Routes>
            {isLoginModalOpen && <LoginModal closeLoginModal={closeLoginModal} />}
        </div>
    );
}

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <AppContent />
                    <ToastContainer
                        position="bottom-center" // 알람 위치 지정
                        autoClose={3000} // 자동 off 시간
                        hideProgressBar={true} // 진행시간바 숨김
                        closeOnClick // 클릭으로 알람 닫기
                        rtl={false} // 알림 좌우 반전
                        pauseOnFocusLoss // 화면을 벗어나면 알람 정지
                        draggable // 드래그 가능
                        pauseOnHover // 마우스를 올리면 알람 정지
                        theme="dark"
                    />
                </Router>
            </PersistGate>
        </Provider>
    );
}

export default App;