
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import common from '../../../common';

import { TableList } from "../../../components";
import styles from './Dept.module.scss';
import 'primeicons/primeicons.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Contact() {
    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const [userData, setUserData] = useState([]);//
    const getMember = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_organization/out/get_list`,
                {
                    committee_category: '사무처',
                    committee_name: '전체'
                },
                { withCredentials: true }
            );
            if (response && response.data && response.data.result === 't') {
                // setGetData(response.data.data)
                setUserData(response.data.data)
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };
    useEffect(() => {
        getMember();
    }, []);

    // {
    //     "email": "kjh@naek.or.kr",
    //     "idx": 3,
    //     "name": "김종훈 수석연구원",
    //     "organization": "정책·지원팀장",
    //     "phone": "02-6009-4002",
    //     "position": "수석연구원"
    //   }

    // const data = useMemo(() => [
    //     { member_name: '김종훈', member_duty: '수석연구원', member_organ: '정책·지원팀', phone: '02-6009-4002', email: 'kjh@naek.or.kr' },
    //     { member_name: '윤관영', member_duty: '책임연구원', member_organ: '정책·지원팀', phone: '02-6009-4011', email: 'yky@naek.or.kr' },
    //     { member_name: '이승영', member_duty: '선임연구원', member_organ: '정책·지원팀', phone: '02-6009-4009', email: 'lsy@naek.or.kr' },
    //     { member_name: '배현진', member_duty: '선임연구원', member_organ: '정책·지원팀', phone: '02-6009-4008', email: 'beabea@naek.or.kr' },
    //     { member_name: '김윤진', member_duty: '연구원', member_organ: '정책·지원팀', phone: '02-6009-4007', email: 'yjk@naek.or.kr' },
    //     { member_name: '민영기', member_duty: '연구원', member_organ: '정책·지원팀', phone: '02-6009-4003', email: 'myg@naek.or.kr' },
    //     { member_name: '김미진', member_duty: '연구원', member_organ: '정책·지원팀', phone: '02-6009-4014', email: 'kmj@naek.or.kr' },
    //     { member_name: '전나희', member_duty: '행정원', member_organ: '정책·지원팀', phone: '02-6009-4013', email: 'nahui@naek.or.kr' },
    //     { member_name: '황선아', member_duty: '책임연구원', member_organ: '회원·사업팀장', phone: '02-6009-4005', email: 'elite80@naek.or.kr' },
    // ], []);

    const columns = useMemo(() => [
        { title: '', dataIndex: 'idx', className: 'display_none' },
        { title: '성명', dataIndex: 'name', className: '' },
        // { title: '직위', dataIndex: 'position', className: '' },
        { title: '소속', dataIndex: 'organization', className: '' },
        { title: '전화번호', dataIndex: 'phone', className: '' },
        { title: '이메일', dataIndex: 'email', className: '' },
    ], []);
    const pageSize = 10;
    const categoryOptions = useMemo(() => [], []);

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <div className={styles.contact_flexwrap}>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI1}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>전화</span>
                            <p>02-6009-4000</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI2}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>팩스</span>
                            <p>02-6009-4019</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI3}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>이메일</span>
                            <p>naek@naek.or.kr</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI4}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>페이스북 페이지</span>
                            <a href="http://www.facebook.com/naek1995" target="_blank" className={styles.link}>
                                facebook.com/naek1995 <i className={`pi pi-external-link ${styles.link}`}></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.member_info_text}>직원 안내</div>
                <div className={styles.table_list_box}>
                    <TableList data={userData} columns={columns} pageSize={pageSize} categoryOptions={categoryOptions} type='organization' />
                </div>
            </div>
        </>
    );
}

export default Contact;