
import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import common from '../../../common';

import styles from './ProjectParti.module.scss';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ProjectParti() {

    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const [detailData, setDetailData] = useState([]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idx = queryParams.get('id');
    
	const [ formData, setFormData ] = useState({
		idx:0,
		col1:'',
		col2:'',
		col3:'',
		col4:'',
		col5:'',
		col6:'',
		col7:'',
		col8:'',
		col9:'',
		col10:'',
		col11:'',
		col12:'',
		col13:'',
		col14:'',
		col15:'',
		col16:'',
		col17:'',
		col18:'',
		file1:'',
		file2:''
	});

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_project/out/get_detail_for_regist`, 
                {
                    target_idx:idx
                }, {
                    withCredentials: true
                }
            );
            if (response.data.result === 't') {
                const data = response.data.data;
                setDetailData(data)
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    
    const proSubmit = async () => {
        console.log(formData)
        try {
            pLoadingOn();
            // const response = await axios.post(`${API_BASE_URL}/naek_project/out/get_detail_for_regist`, 
            //     {
            //         target_idx:idx
            //     }, {
            //         withCredentials: true
            //     }
            // );
            // if (response.data.result === 't') {
            //     const data = response.data.data;
            //     setDetailData(data)
            // } else {
            //     pAlert(response.data.msg);;
            // }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };
    
    const onChg_file = (e) => {
		const newFile = Array.from(e.target.files);
        setFormData((prevData) => ({
            ...prevData,
            [e.target.dataset.idx]:newFile,
        }))
    };

    const colOrder = ["col1", "col2", "col3", "col4", "col5", "col6", "col7", "col8", "col9", "col10", "col11", "col12", "col13", "col14", "col15", "col16", "col17", "col18"];
    const fileOrder = ["file1", "file2"];

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <div className="p_event_wrap">
                    <div className="event_info_box">
                        <span className="event_title">{detailData.title}</span>
                        <table className='event_table'>
                            <tbody>
                                <tr>
                                    <th>참여기간</th>
                                    <td>{detailData.date}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={`p_i_row ${styles.projectregist_form_box}`}>
                            {colOrder.map((colKey) => {
                                const value = detailData[colKey];
                                if (value !== null) {
                                    console.log(colKey)
                                    return (
                                        <div className={`p_i p_i_4 ${styles.pr_f_item}`} key={colKey}>
                                            <span className={`p_i_tit f_small ${styles.pr_f_i_tit}`}>{value}</span>
                                            <div className={`p_i_con_box ${styles.pr_f_i_con_box}`}>
                                                <input type="text" className={`p_ip ${styles.pr_f_i_input}`}
                                                    value={formData[colKey]}
                                                    onChange={(e) => setFormData((prevData) => ({
                                                        ...prevData,
                                                        [colKey]: e.target.value  // colKey를 동적으로 업데이트
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                        <div className={` ${styles.projectregist_form_box} ${styles.projectregist_form_file_box}`}>
                            {fileOrder.map((colKey) => {
                                const value = detailData[colKey];
                                if (value !== null) {
                                    console.log(colKey)
                                    return (
                                        <>
                                            <div className={`p_i ${styles.pr_f_item}`} key={colKey}>
                                                <span className={`p_i_tit f_small ${styles.pr_f_i_tit}`}>{value}</span>
                                                <div className={`p_i_con_box ${styles.pr_f_i_con_box}`}>
                                                    {formData[colKey]=='' ? (
                                                        <>
                                                            <label htmlFor={`tmpFile_${colKey}`} className={styles.file_item}>
                                                                <input className='p_ip' type="text" readOnly value='선택된 파일 없음' style={{ flex: 1, pointerEvents: 'none' }} />
                                                                <button className={`p_btn f_small p_btn_blue ${styles.file_on}`} onClick={() => document.getElementById(`tmpFile_${colKey}`).click()}>파일선택</button>
                                                            </label>
                                                            <input
                                                                type="file"
                                                                data-idx={colKey}
                                                                id={`tmpFile_${colKey}`}
                                                                style={{display: 'none'}}
                                                                onChange={onChg_file}
                                                            />
                                                        </>
                                                    ):(
                                                        <>
                                                            <div className={styles.file_item}>
                                                                <input className='p_ip' type="text" readOnly value={formData[colKey].name} />
                                                                <button
                                                                    onChange={(e) => setFormData((prevData) => ({
                                                                        ...prevData,
                                                                        [colKey]: ''
                                                                    }))}
                                                                    className='p_btn f_small '>삭제</button>
                                                            </div>
                                                        </>
                                                    )}


                                                </div>
                                            </div>
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </div>
                        <div className='p_i_sec'>
                            <div className='p_i'>
                                <div className='p_i_con_box'>
                                    <span className='f_normal before_required'>신청이 완료되면 내용을 수정할 수 없습니다.</span>
                                </div>
                            </div>
                        </div>
                        <div className='p_i_sec'>
                            <div className='p_i'>
                                <div className='p_i_con_box center'>
                                    <button onClick={() => proSubmit()} className='p_btn p_btn_lg p_btn_blue mgl10'>신청</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectParti;