
import React, { useState, useEffect, useMemo } from 'react';
import { TableList } from "../../../../components";
import styles from './Contact.module.scss';
import 'primeicons/primeicons.css';

import axios from 'axios';
import common from '../../../../common';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Contact() {
    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    // const data = useMemo(() => [
    //     { member_name: 'Jonghoon Kim', member_duty: 'Chief Researcher', member_organ: 'Policy and Management Support Team (Leader)', phone: '02-6009-4002', email: 'kjh@naek.or.kr' },
    //     { member_name: 'Kwanyoung Yoon', member_duty: 'Principal Researcher', member_organ: 'Policy and Management Support Team', phone: '02-6009-4011', email: 'yky@naek.or.kr' },
    //     { member_name: 'Seungyoung Lee', member_duty: 'Senior Researcher', member_organ: 'Policy and Management Support Team', phone: '02-6009-4009', email: 'lsy@naek.or.kr' },
    //     { member_name: 'Hyunjin Bae', member_duty: 'Senior Researcher', member_organ: 'Policy and Management Support Team', phone: '02-6009-4008', email: 'beabea@naek.or.kr' },
    //     { member_name: 'Yunjin Kim', member_duty: 'Researcher', member_organ: 'Policy and Management Support Team', phone: '02-6009-4007', email: 'yjk@naek.or.kr' },
    //     { member_name: 'Yeonggi Min', member_duty: 'Researcher', member_organ: 'Policy and Management Support Team', phone: '02-6009-4003', email: 'myg@naek.or.kr' },
    //     { member_name: 'Mijin Kim', member_duty: 'Researcher', member_organ: 'Policy and Management Support Team', phone: '02-6009-4014', email: 'kmj@naek.or.kr' },
    //     { member_name: 'Nahui Jeon', member_duty: 'Administrator', member_organ: 'Policy and Management Support Team', phone: '02-6009-4013', email: 'nahui@naek.or.kr' },
    //     { member_name: 'Seon-Ah Hwang', member_duty: 'Principal Researcher', member_organ: 'Program and Membership Management Team (Leader)', phone: '02-6009-4005', email: 'elite80@naek.or.kr' },
    //     { member_name: 'Jiesoo Yoon', member_duty: 'Senior Researcher', member_organ: 'Program and Membership Management Team', phone: '02-6009-4006', email: 'yjs@naek.or.kr' },
    //     { member_name: 'Shinhye Kim', member_duty: 'Researcher', member_organ: 'Program and Membership Management Team', phone: '02-6009-4001', email: 'shk@naek.or.kr' },
    //     { member_name: 'Jonghyun Shin', member_duty: 'Researcher', member_organ: 'Program and Membership Management Team', phone: '02-6009-4015', email: 'sjh@naek.or.kr' },
    //     { member_name: 'Chanmi Lee', member_duty: 'Researcher', member_organ: 'Program and Membership Management Team', phone: '02-6009-4010', email: 'cmlee@naek.or.kr' },
    //     { member_name: 'Young-Lan Lee', member_duty: 'Researcher', member_organ: 'Program and Membership Management Team', phone: '02-6009-4000', email: 'lyl@naek.or.kr' },
    //     { member_name: 'Yeseul Sa', member_duty: 'Researcher', member_organ: 'Program and Membership Management Team', phone: '02-6009-4020', email: 'sys@naek.or.kr' },
    // ], []);

    const [userData, setUserData] = useState([]);//
    const getMember = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_organization/out/get_list`,
                {
                    committee_category: 'contact',
                },
                { withCredentials: true }
            );
            if (response && response.data && response.data.result === 't') {
                // setGetData(response.data.data)
                setUserData(response.data.data)
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };
    useEffect(() => {
        getMember();
    }, []);


    const columns = useMemo(() => [
        { title: '', dataIndex: 'idx', className: '' },
        { title: 'Name', dataIndex: 'name', className: '' },
        { title: 'Affiliation', dataIndex: 'organization', className: '' },
        { title: 'Phone number', dataIndex: 'phone', className: '' },
        { title: 'Email', dataIndex: 'email', className: '' },
    ], []);
    const pageSize = 10;
    const categoryOptions = useMemo(() => [
        { label: 'Entire', value: '전체' },
        { label: 'Name', value: 'member_name' },
    ], []);


    return (
        <>
            <LoadingComponent/>
            <div className="components_wrap">
                <div className={styles.contact_flexwrap}>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI1}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>Phone number</span>
                            <p>02-6009-4000</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI2}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>Fax number</span>
                            <p>02-6009-4019</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI3}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>e-mail</span>
                            <p>naek@naek.or.kr</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI4}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>Facebook page</span>
                            <a href="http://www.facebook.com/naek1995" target="_blank" className={styles.link}>
                                facebook.com/naek1995 <i className={`pi pi-external-link ${styles.link}`}></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.member_info_text}>Staff Directory</div>
                <div className={styles.table_list_box}>
                    <TableList data={userData} columns={columns} pageSize={pageSize} categoryOptions={categoryOptions} type="contact" />
                </div>
            </div>
        </>
    );
}

export default Contact;








