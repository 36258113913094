import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Calendar.module.scss';
import { Calendar } from "../../../components";
import { TableList } from "../../../components";
import { useLocation, useNavigate } from 'react-router-dom';
import Block from '../../../Block';
import common from '../../../common';

import moment from "moment";

const { useAlert } = common();

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function CalendarEventPage({ isLogin }) {
    
	const { useAlert, useLoading } = common();
	const { pAlert, AlertComponent } = useAlert();
	const { pLoadingOn, pLoadingOff,LoadingComponent} = useLoading();

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const pageid = queryParams.get('pageid');

    // activeTab 상태를 관리하여 현재 활성화된 탭을 추적합니다.
    const [activeTab, setActiveTab] = useState(pageid === '2' ? 'eventParti' : 'calendar');

    // pageid가 변경될 때마다 activeTab을 업데이트합니다.
    useEffect(() => {
        setActiveTab(pageid === '2' ? 'eventParti' : 'calendar');
        if(pageid === '2'){
            if (isLogin) {
                setData();
            }
        }
    }, [pageid]);

    // 탭 클릭 시 호출되는 함수로, 페이지를 전환하고 URL을 업데이트합니다.
    const handleTabClick = (tabName) => {
        const newPageId = tabName === 'calendar' ? '1' : '2';
        navigate(`/calendar?pageid=${newPageId}`);
    };


    // 테이블 컬럼 정의
    const columns = [
        { title: '행사명', dataIndex: 'title', className: 'p_td_title p_td_w_60' },
        { title: '행사종류', dataIndex: 'type', className: 'p_td_event_type p_td_f_16' },
        { title: '장소', dataIndex: 'place', className: 'p_td_event_place p_td_f_16' },
        {
            title: '일시',
            dataIndex: 'date',
            className: 'p_td_event_date',
            render: (text) => {
                const currentTime = moment();
                const recordTime = moment(text);
                const isFuture = recordTime.isAfter(currentTime);
                return (
                    <span className={isFuture ? "future-date" : ""}>{text}</span>
                );
              }
        },
    ];

    const [events, setEvents] = useState([]);
    const setData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_event/in/get_my_table`, { }, { withCredentials: true });
        
            if (response && response.data && response.data.result === 't') {
                setEvents(response.data.data);
            } else {
                console.error(response.data);
                setEvents([]);
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
            setEvents([]);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 100);
        }
    };

    const pageSize = 10;

    const categoryOptions = [
        { label: '전체', value: '전체' },
        { label: '제목', value: 'title' },
        { label: '내용', value: '' },
    ];

    // if (!isLogin && pageid === '2') {
    //     return <Block />;
    // }

    return (
        <>
            <AlertComponent />
            <div className={styles.components_wrap}>
                <div className={styles.top_content_wrap}>
                    <div className={styles.tab_box}>
                        <a
                            href="/#"
                            className={`${styles.tab_btn} ${styles.calendar_btn} ${activeTab === 'calendar' ? styles.tab_btn_active : ''}`}
                            onClick={(e) => { e.preventDefault(); handleTabClick('calendar'); }}
                        >
                            행사 캘린더
                        </a>
                        <a
                            href="/#"
                            className={`${styles.tab_btn} ${styles.eventParti_btn} ${activeTab === 'eventParti' ? styles.tab_btn_active : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                // 임시
                                // handleTabClick('eventParti');
                                if (isLogin) {
                                    handleTabClick('eventParti');
                                } else {
                                    pAlert('로그인이 필요합니다.');
                                }
                            }}
                        >
                            행사참여
                        </a>
                    </div>
                </div>
                <div className={styles.bottom_content_wrap}>
                    {activeTab === 'calendar' && <Calendar />}
                    {activeTab === 'eventParti' && <TableList data={events} columns={columns} pageSize={pageSize} categoryOptions={categoryOptions} type="Event" />}
                </div>
            </div>
        </>
    );
}

export default CalendarEventPage;
